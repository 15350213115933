.--margin-xs {
  margin-top: 4px !important;
}

.--margin-sm {
  margin-top: 8px !important;
}

.--margin-md {
  margin-top: 16px !important;
}

@media (max-width: 600px) {
  .--margin-md {
    margin-top: 0px !important;
  }
}

.--margin-lg {
  margin-top: 32px !important;
}

.--margin-xl {
  margin-top: 64px !important;
}
