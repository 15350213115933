@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply outline-none;
  }

  body {
    @apply m-0 text-base max-w-full tracking-wide;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply 2xl:font-light;
  }

  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-1.5;
  }

  h1 {
    @apply text-4xl;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-2xl;
  }

  h4 {
    @apply text-xl;
  }

  h6 {
    @apply text-lg;
  }

  h6 {
    @apply text-base;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  li {
    @apply ml-5;
  }

  table {
    tr {
      td {
        @apply border-gray-300 border p-3;
      }
    }
  }
}

@layer components {
  .truncate-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .truncate-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .container-list {
    @apply bg-white  flex flex-col  shadow-md h-full;
  }
  .open-menu {
    @apply animate-openMenu w-90;
  }

  .close-menu {
    @apply animate-closeMenu w-0;
  }
  .aspect-15-3 {
    aspect-ratio: 15/3;
  }

  .aspect-5-3 {
    aspect-ratio: 5/3;
  }

  .aspect-11-3 {
    aspect-ratio: 11/3;
  }

  .contenedor {
    @apply mx-auto gap-6 w-full;
    max-width: 90%;
  }

  .item {
    @apply flex
    2xl:content-center
    items-center;
  }

  .input-filter {
    @apply shadow appearance-none
    border rounded
    w-full py-2 px-3
    focus:outline-none;
  }

  .fas {
    opacity: 0.85;
  }

  .contenedor-error {
    @apply flex
    flex-col
    justify-center
    h-full;
  }

  .container {
    @apply w-11/12
    sm:max-w-full
    mx-auto;
  }

  .backdrop {
    background: #f5f5f5;
    opacity: 0.8;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    animation: ui-backdrop-enter 0.5s;
  }

  .backdrop-item {
    position: relative;
    z-index: 1;
  }

  .data-empty {
    @apply text-2xl
    opacity-70
    mx-auto;
  }

  .btn-primary {
    @apply inline-block
    font-medium
    py-3
    px-8
    text-white
    bg-azul-pos
    transition-all
    duration-300
    hover:bg-azul-obscuro-pos
    ease-in-out
    rounded
    border
    border-azul-pos
    hover:border-azul-obscuro-pos
    hover:shadow-md
    select-none
    outline-none
    cursor-pointer;
  }

  .btn-gray {
    @apply inline-block
    font-medium
    py-3
    px-8
    text-white
    bg-gris
    transition-all
    duration-300
    hover:bg-gris-obscuro
    hover:border-gris-obscuro
    ease-in-out
    rounded
    border
    border-gris
    hover:shadow-md
    select-none
    outline-none
    cursor-pointer;
  }

  //TODO: este selected de que es
  .btn.selected {
    @apply bg-azul-pos
    border-azul-pos
    hover:bg-azul-obscuro-pos
    hover:border-azul-obscuro-pos;
  }

  .p-InputText {
    padding: 0.125rem 0.5rem !important;
    @apply focus:outline-none;
  }

  .p-autocomplete-input {
    width: 100% !important;
  }

  .p-calendar {
    width: 100% !important;
  }

  .at-control_messages {
    @apply text-red-700
    text-xs;
  }

  .text-error {
    @apply text-red-700
    text-sm;
  }

  .button {
    @apply rounded
    text-white
    bg-blue-600
    hover:bg-blue-700
    text-sm
    m-0
    px-2
    h-8
    self-center;
  }

  .button.button-secondary {
    @apply bg-gray-500;
  }

  .button.button-error {
    @apply bg-red-600
    hover:bg-red-700;
  }

  .button.button-sm {
    @apply h-6;
  }

  .step {
    @apply pr-2
    py-2
    hover:text-blue-600
    hover:border-blue-600
    font-medium
    cursor-pointer
    text-gray-600;
  }

  .step.selected {
    @apply text-blue-600
    border-blue-600
    border-b-2;
  }

  .p-menu.p-component {
    @apply ml-12;
  }

  .p-tabview .p-tabview-nav {
    background: #fdfdfdff !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: #fdfdfdff !important;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #fdfdfdff !important;
  }

  .p-tabview .p-tabview-panels {
    background: #fdfdfdff !important;
  }

  .p-dropdown,
  .p-multiselect {
    width: 100% !important;
    align-items: center;
  }

  .p-component.p-dropdown {
    @apply w-full;
  }

  .avatar {
    @apply select-none
    rounded-full
    w-12
    h-12
    md:w-12
    md:h-12
    sm:w-10
    sm:h-10
    bg-azul-pos
    text-white
    font-medium
    text-lg
    inline-flex
    items-center
    justify-center;
  }

  .item-container {
    @apply shadow-sm
    rounded-sm
    w-full
    max-w-full
    flex
    items-center
    py-3
    border
    border-gris-claro
    items-center;
  }

  .icon {
    @apply outline-none
    focus:outline-none
    hover:bg-opacity-100
    px-2
    py-0.5
    text-center;
  }

  .--text-clip {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .icon:hover {
    background: #e5e5e58c;
    border-radius: 8px;
  }

  .p-card-content {
    padding: 0 !important;
  }

  /* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 10px;
  }

  ::-webkit-scrollbar-button:increment,
  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #79797987;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .autocomplete-container {
    box-shadow: none !important;
    /* position: relative; */
    /* overflow: visible; */
    /* height: 40px; */
    border: 1px solid #cecece !important;
    padding: 8px !important;
  }

  .autocomplete-container .input-container input {
    font-size: 14px;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    outline: 0;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    padding: 0 15px;
    line-height: 40px;
    height: 40px;
  }
}
