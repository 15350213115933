@import 'variables';
/* You can add global styles to this file, and also import other style files */

@import 'style/--cursor/style.scss';
@import 'style/--margin/style.scss';
@import 'style/--opacity/style.scss';
@import 'style/--text/style.scss';
@import 'style/at-control/style.scss';
@import 'style/at-fieldset/style.scss';
@import 'style/any/padding.scss';
@import 'style/text.scss';
@import 'style/at-icon/style.scss';
@import 'style/at-item/style.scss';
@import 'style/card/card.scss';
@import 'style/item/item.scss';
@import 'style/at-card/style.scss';
@import 'style/flex.scss';
@import 'style/responsive.scss';
@import 'style/modal/modal';
@import '~angular-notifier/styles';

@font-face {
  font-family: 'BeVietnam-Bold';
  src: url('assets/fonts/BeVietnam-Bold.ttf');
}

@font-face {
  font-family: 'BeVietnam-Regular';
  src: url('assets/fonts/BeVietnam-Regular.ttf');
}

@font-face {
  font-family: 'Quicksand-Bold';
  src: url('assets/fonts/Quicksand-Bold.ttf');
}

@font-face {
  font-family: 'Quicksand-Regular';
  src: url('assets/fonts/Quicksand-Regular.ttf');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fuente-Titulos;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: $color-oscuro;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1rem;
}

html {
  box-sizing: border-box;
}

body {
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  font-family: $fuente-principal;
  margin: 0;
  outline-offset: 0;
  outline: 0;
  background-color: #f7f7f7;
}

.contenedor-small {
  margin: 0 auto;
  gap: 1.5rem;
  width: 90%;
  max-width: 1200px;
}

.contenedor-hero {
  width: 100%;
}

.hero {
  height: 100vh;
  background-image: url('assets/img/inicio/fondo-hero.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 2fr;

  h1 {
    color: $color-claro;
  }
}

.iti {
  width: 100%;
}

form.busqueda {
  width: 100%;
  background-color: $color-claro;
  height: auto;
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0;
}

.card-2 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}

.card-3 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}

.card-horizontal-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .card-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  form.busqueda-avanzada-open {
    > div:nth-child(6),
    div:nth-child(7),
    div:nth-child(8),
    div:nth-child(9) {
      border-top: solid 1px $color-gris-claro;
    }

    > div:nth-child(9) {
      border-right: 0;
    }
  }
  form.busqueda {
    > div {
      border-right: solid 1px $color-gris-claro;
      padding: 0.75rem;

      label {
        display: block;
        color: $color-principal;
        margin-bottom: 5px;
      }

      .buscar {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-left: 10%;
        width: 100%;
        margin: 0;
        height: 100%;
        border: none;
        outline: 0;
        color: $color-claro;
        font-family: $fuente-principal-bold;
        background-color: $color-principal;
        border-radius: 16px;
        font-size: 1.2rem;
        transition: all ease 500ms;
        user-select: none;
        user-select: none;
        white-space: pre;
        align-items: flex-start;
        text-align: center;
        display: inline-block;
        cursor: pointer;
      }

      .buscar:hover {
        border: 3px solid $color-principal;
        background-color: $color-claro;
        color: $color-principal;
        transition: all ease 500ms;
      }
    }

    > div:nth-child(4),
    > div:nth-child(5) {
      border: none;
    }

    > div:nth-child(5) {
      padding: 0;
    }
  }
  .busqueda-avanzada {
    color: $color-claro;
    text-align: right;
    font-family: $fuente-principal-bold;
    font-size: 1.2rem;
    display: flex;
    float: right;
    margin-top: 6px;
    cursor: pointer;
  }
  .busqueda-avanzada:hover {
    color: $color-gris-claro;

    .icon-avanzadas,
    .icon-avanzadas-cerrar {
      filter: contrast(0.8);
    }
  }
}

.subtitulo {
  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  .barra {
    background-color: $color-principal;
    display: inline-block;
    height: 3px;
    margin-bottom: 0.5rem;
    width: 70px;
  }
}

//nosotros
.fondo-comentarios {
  background-image: url('assets/img/nosotros/fongo-comentarios.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

//blog
.fondo-header-blog {
  background-image: url('assets/img/blog/header-blog.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 0;
}

@media (max-width: 575.98px) {
  .buscarP {
    .buscar {
      width: 90%;
      left: 5%;
      display: inline-block;
      text-align: center;
      position: fixed;
      z-index: 1;
      bottom: 0;
      border-radius: 6px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      span {
        left: 30%;
      }
    }
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  .contenedor-busqueda-destacada {
    padding: 0 !important;
    padding-bottom: 2rem !important;

    a {
      text-align: center !important;
    }
  }
}

h2 {
  span {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .seccion {
    padding: 1rem 0 !important;
  }
  h2 {
    position: relative;

    span {
      display: inline-block;
      position: absolute;
      right: 0;
      color: $color-principal;
      transition: color ease-in 300ms;
    }

    span:hover {
      cursor: pointer;
      color: $color-secundario;
      transition: color ease-in 300ms;
    }
  }
  h3 {
    font-size: 1.3rem !important;
  }
}

@media (min-width: 768px) {
  .menu {
    //background: linear-gradient(90.6deg, rgba(240, 240, 240, 0.5) 38.27%, rgba(242, 242, 242, 0.5) 75.76%);
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-primary {
  @apply inline-block rounded-md text-base text-white py-3 px-12 mx-auto no-underline cursor-pointer select-none;
  background-color: $color-principal;
  border-color: $color-principal;
}

.btn-primary:hover {
  background-color: $color-secundario;
  border-color: $color-secundario;
}

.btn-primary:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.38);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  background-color: $color-secundario;
  border-color: $color-secundario;
  outline: 0;
}

.btn-secondary {
  @apply inline-block rounded-md text-base text-white py-3 px-12 mx-auto no-underline cursor-pointer select-none;
  background-color: $color-secundario;
  border-color: $color-secundario;
}

.btn-secondary:hover {
  background-color: $color-principal;
  border-color: $color-principal;
}

.btn-secondary:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.38);
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:focus {
  background-color: $color-principal;
  border-color: $color-principal;
  outline: 0;
}

textarea {
  font-family: $fuente-principal !important;
  color: #aaa !important;
}

blockquote {
  border-left: 5px solid $color-principal;
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 30px;
  background: #efefef;
}

.text-danger {
  color: #ff6600;
  font-size: 0.8rem;
}

.form-control {
  margin-bottom: 1rem;
}

form {
  .ng-touched.ng-invalid.fecha {
    input {
      border: 1.5px solid #ff6600;
    }
  }

  :required.ng-touched.ng-invalid {
    border: 1.5px solid #ff6600;
  }
}

.encabezado {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 2rem 0;
}

.p-multiselect {
  width: 100% !important;
}

input[type='submit'] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.multipleSelectSize {
  height: 30px;
  min-width: 100px;
}

.multipleSelectSize > .ui-multiselect-label-container > .ui-multiselect-label {
  height: 27px;
}

.p-multiselect-label-container {
  @apply grid;
}

.p-dropdown,
.p-multiselect,
.p-multiselect-filter.p-inputtext,
.input-item,
.p-calendar .p-inputtext {
  border: none;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  height: 3rem;
  width: 100%;
  transition: all 300ms ease-in-out;
}

.input-item {
  padding: 0.5rem 0.5rem;
}

.input-item:focus {
  outline: none;
}

.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus,
.p-multiselect:hover,
.input-item:hover {
  border: none;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
}

.p-multiselect {
  align-items: center;
}

.p-dropdown .p-dropdown-trigger,
.p-multiselect .p-multiselect-trigger,
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  color: $color-principal;
}

.cita-dialog .p-dialog-content {
  display: inline-table;
}

.rango {
  margin: 4%;
}

.input-item-principal.p-dropdown:not(.p-disabled):hover,
.input-item-principal.p-dropdown:not(.p-disabled).p-focus,
.input-item-principal.p-inputtext:enabled:focus,
.input-item-principal.p-multiselect:hover,
.input-item-principal.p-dropdown,
.input-item-principal {
  box-shadow: none;
}

.admin-item-lista {
  display: flex;
}

.loading {
  display: flex;
  justify-content: center;
  aling-self: center;
  align-items: center;
}

.card {
  box-shadow: 0 -1px 0 var(--divider, rgba(0, 0, 0, 0.05)), 0 1px 0 var(--divider, rgba(0, 0, 0, 0.05)),
    0 2px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  color: var(--card__text, rgba(0, 0, 0, 0.8));
  background-color: var(--card__color, #fdfdfd);
  overflow-wrap: break-word;
}

.card-group {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  & .card {
    box-shadow: 0 -1px 0 var(--divider, rgba(0, 0, 0, 0.05)), 0 1px 0 var(--divider, rgba(0, 0, 0, 0.05));
  }
}

.p-inputtext,
.p-inputgroup-addon {
  padding: 0.125rem 0.5rem !important;
}

.p-multiselect .p-multiselect-label {
  padding: 0.125rem 0.5rem !important;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 0.5rem 0.5rem !important;
  border-width: 0 !important;
}

.p-toast-message-success {
  background: #a0eaa18f !important;
  border: solid #08330a !important;
  border-width: 0 0 0 6px !important;
  color: #163c1b !important;
}

.p-toast-message-icon {
  display: none !important;
}

.hoverable {
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    background: #000;
    opacity: 0;
    position: absolute;
    transition: opacity 250ms;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:hover,
  &:focus {
    &:before {
      opacity: 0.05;
    }
  }

  &:active {
    &:before {
      opacity: 0.1;
    }
  }
}

.typo-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.typo-body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.typo-body-2 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.typo-subhead {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.typo-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.8;
}

.typo-headline {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  opacity: 0.85;
}

.item {
  @apply flex
  2xl:content-center
  items-center;
}

.input-filter {
  @apply shadow appearance-none
  border rounded
  w-full py-2 px-3
  focus:outline-none;
}

.fas {
  opacity: 0.85;
}

.contenedor-error {
  @apply flex
  flex-col
  justify-center
  h-full;
}

.container {
  @apply w-11/12
  sm:max-w-full
  mx-auto;
}

.backdrop {
  background: #f5f5f5;
  opacity: 0.8;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  animation: ui-backdrop-enter 0.5s;
}

.backdrop-item {
  position: relative;
  z-index: 1;
}

.data-empty {
  @apply text-2xl
  opacity-70
  mx-auto;
}

.btn-gray {
  @apply inline-block
  font-medium
  py-3
  px-8
  text-white
  bg-gris
  transition-all
  duration-300
  hover:bg-gris-obscuro
  hover:border-gris-obscuro
  ease-in-out
  rounded
  border
  border-gris
  hover:shadow-md
  select-none
  outline-none
  cursor-pointer;
}

//TODO: este selected de que es
.btn.selected {
  @apply bg-azul-pos
  border-azul-pos
  hover:bg-azul-obscuro-pos
  hover:border-azul-obscuro-pos;
}

.p-InputText {
  padding: 0.125rem 0.5rem !important;
  @apply focus:outline-none;
}

.p-autocomplete-input {
  width: 100% !important;
}

.p-calendar {
  width: 100% !important;
}

.at-control_messages {
  @apply text-red-700
  text-xs;
}

.text-error {
  @apply text-red-700
  text-sm;
}

.button {
  @apply rounded
  text-white
  bg-blue-600
  hover:bg-blue-700
  text-sm
  m-0
  px-2
  h-8
  self-center;
}

.button.button-secondary {
  @apply bg-gray-500;
}

.button.button-error {
  @apply bg-red-600
  hover:bg-red-700;
}

.button.button-sm {
  @apply h-6;
}

.step {
  @apply pr-2
  py-2
  hover:text-blue-600
  hover:border-blue-600
  font-medium
  cursor-pointer
  text-gray-600;
}

.step.selected {
  @apply text-blue-600
  border-blue-600
  border-b-2;
}

.p-menu.p-component {
  @apply ml-12;
}

.p-tabview .p-tabview-nav {
  background: #fdfdfdff !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: #fdfdfdff !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #fdfdfdff !important;
}

.p-tabview .p-tabview-panels {
  background: #fdfdfdff !important;
}

.p-dropdown,
.p-multiselect {
  width: 100% !important;
  align-items: center;
}

.p-component.p-dropdown {
  @apply w-full;
}

.avatar {
  @apply select-none
  rounded-full
  w-12
  h-12
  md:w-12
  md:h-12
  sm:w-10
  sm:h-10
  bg-azul-pos
  text-white
  font-medium
  text-lg
  inline-flex
  items-center
  justify-center;
}

.item-container {
  @apply shadow-sm
  rounded-sm
  w-full
  max-w-full
  flex
  items-center
  py-3
  border
  border-gris-claro
  items-center;
}

.icon {
  @apply outline-none
  focus:outline-none
  hover:bg-opacity-100
  px-2
  py-0.5
  text-center;
}

.--text-clip {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon:hover {
  background: #e5e5e58c;
  border-radius: 8px;
}

.p-card-content {
  padding: 0 !important;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #79797987;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

.autocomplete-container {
  box-shadow: none !important;
  /* position: relative; */
  /* overflow: visible; */
  /* height: 40px; */
  border: 1px solid #cecece !important;
  padding: 8px !important;
}

.autocomplete-container .input-container input {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: 0;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  padding: 0 15px;
  line-height: 40px;
  height: 40px;
}

//prime elements
.p-paginator .p-paginator-pages .p-paginator-page, .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  border-radius: 50%;
}
@media (max-width: 768px) {
  .carousel-responsive.p-carousel {
    .p-carousel-container {
      @apply relative;
      > button {
        border: solid 1px rgb(233, 233, 233);
        @apply absolute bg-white shadow-md  z-10 w-10 h-10;
      }

      > button:first-of-type {
        @apply left-0;
      }
      > button:last-of-type {
        @apply right-0;
      }
    }
  }
}
.check-small.p-checkbox {
  width: 14px;
  height: 14px;
  .p-checkbox-box {
    width: 14px;
    height: 14px;
  }
}
.crm-opciones-mensaje.p-sidebar .p-sidebar-header + .p-sidebar-content {
  height: 100%;
  overflow: inherit !important;
}
.crm-calendar.p-calendar .p-datepicker {
  right: 0 !important;
}
.crm-agentes.p-dialog .p-dialog-header + .p-dialog-content {
  overflow: inherit !important;
}

input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file']::-webkit-file-upload-button,
button {
  border-radius: 6px;
}

.p-tieredmenu .p-menuitem-link {
  margin-bottom: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-left: 0;

  button {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $color-principal;
    background-color: transparent;
  }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: $color-principal;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: $color-secundario;
  border: 1px solid $color-secundario;
}

.renta {
  .p-carousel-indicators .p-carousel-indicator button {
    border: 1px solid $color-alternativo;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: $color-alternativo;
    border: 1px solid $color-alternativo;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: $color-alternativo;
  }

  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    color: $color-alternativo;
  }

  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
  .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: $color-alternativo;
  }

  .p-link:focus,
  .p-carousel .p-carousel-content .p-carousel-prev:focus,
  .p-carousel .p-carousel-content .p-carousel-next:focus {
    box-shadow: 0 0 0 0.2rem #f2faa6 !important;
  }
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: $color-principal;
  border-color: transparent;
  background: $color-gris-claro;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  color: $color-principal;
}

.p-carousel-item {
  @apply p-4;
}

//Desarrollo slider
.desarrollo-slider {
  .p-carousel-content {
    @apply grid relative overflow-hidden;
  }

  button.p-carousel-prev.p-link.p-ripple,
  button.p-carousel-next.p-link.p-ripple {
    @apply absolute z-10;
    background-color: rgba(255, 255, 255, 0.5);
  }

  button.p-carousel-prev.p-link.p-ripple {
    @apply left-0;
  }

  button.p-carousel-next.p-link.p-ripple {
    @apply right-0;
  }

  .p-carousel-item {
    @apply p-0 relative;
  }

  .p-carousel-indicators {
    @apply absolute left-0 right-0 m-auto bottom-0;

    .p-carousel-indicator.p-highlight {
      @apply opacity-90;
    }

    .p-carousel-indicator {
      @apply opacity-50;

      button {
        background-color: rgba(255, 255, 255, 0.5);
        border: 2px solid rgba(255, 255, 255, 0.5);
        @apply h-4 w-4 rounded-full transition-colors duration-200 transition-shadow;
      }
    }
  }
}

//Desarrollo inmuebles relacionados
.card-relacionados {
  .card {
    border-radius: 4px !important;

    .card-body {
      @apply flex flex-wrap items-center justify-between;

      .card-title {
        order: 1;
        width: 60%;
        font-family: $fuente-principal-bold !important;
      }

      .precio {
        order: 2;
        border-radius: 6px;
        width: auto;
        text-align: right;
        font-size: 1.2rem;
        color: $color-principal !important;
      }

      .desc {
        order: 3;
        width: 100%;
        -webkit-line-clamp: 4 !important;
        margin-top: 0.2rem;
      }

      .caracteristicas {
        order: 4;
        width: 60%;
      }

      .disponibles {
        order: 5;
        width: 40%;
      }
    }

    .img-wrapper {
      border-radius: 4px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

@media (min-width: 992px) {
  .p-calendar .p-datepicker {
    left: unset !important;
    right: -12% !important;
  }
  .card-relacionados .card .card-body .desc {
    -webkit-line-clamp: 2 !important;
  }
}

@media (max-width: 768px) {
  .card-relacionados {
    .card {
      .card-body {
        .desc {
          -webkit-line-clamp: 4 !important;
        }

        .card-title {
          width: 100% !important;
        }
      }
    }
  }
}

.form-inicio {
  .formulario {
    .grid-formulario-container {
      @apply bg-white rounded-2xl grid-cols-1 sm:grid-cols-2 px-8 py-6 gap-x-12 mb-8;
    }
  }
}

.dialog-contactanos {
  .p-dialog {
    @apply md:w-auto;
  }
}

.form-lista {
  .formulario {
    .grid-formulario-container {
      @apply bg-white rounded-2xl grid-cols-1 sm:grid-cols-2  gap-x-12 mb-8;

      .botones-division.tres {
        @apply grid-cols-2;
      }

      .botones-division.cuatro {
        @apply grid-cols-3;
      }

      .p-dialog .p-dialog-content {
        padding: 0 0.5rem 2rem 0.5rem !important;
      }
    }
  }
}
