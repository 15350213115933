@import "../config";

.card {
  @include whiteframe();
  @include scrollbar(8px, hsl(0, 0%, 90%), hsl(0, 0%, 80%), hsl(0, 0%, 80%));
  display: block;
  position: relative;
  color: var(--card__text, $card__text);
  background-color: var(--card__color, $card__color);
  overflow-wrap: break-word;
}

.card-group {
  box-shadow: 0 2px 6px rgba(0, 0, 0, .1);

  & .card {
    box-shadow: 0 -1px 0 var(--divider, $divider), 0 1px 0 var(--divider, $divider);
  }
}
