@import "../config";

.at-item {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  min-height: 48px;

  & > * {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.at-item--small {
  min-height: 32px;
}

.at-item--large {
  min-height: 64px;
}

.at-item--xlarge {
  min-height: 96px;
}

.at-item--reverse {
  flex-direction: row-reverse;
}

.at-item--align-baseline {
  align-items: baseline;
}

.at-item--align-start {
  align-items: flex-start;
}

.at-item--align-end {
  align-items: flex-end;
}
