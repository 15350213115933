@import "../config";

.at-control {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  width: 100%;
}

.at-control_label {
  display: block;
  padding: 0;
  line-height: 22px;
  font-size: $font-size-large;
  padding-right: 5px;
  color: inherit;
  opacity: $label-opacity;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.at-control_label--required:after {
  content: " *";
}

.at-control_messages {
  display: block;
  padding: 0;
  font-size: $font-size-small;
  color: var(--error__color, $error__color);
  user-select: none;
  overflow: hidden;
  position: absolute;
  bottom: -16px;
  left: 5px;

  & > * {
    animation: at-control__messages--animation 150ms ease-in;
  }
}

@keyframes at-control__messages--animation {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
