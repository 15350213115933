/**
Remake
 */
// Blackboard
$blackboard__color: hsl(0, 0%, 94%);
$blackboard__text: rgba(0, 0, 0, 0.6);

// Card
$card__color: #fdfdfd;
$card__text: rgba(0, 0, 0, 0.8);

// Menu
$menu__color: #fdfdfd;
$menu__text: rgba(0, 0, 0, 0.8);

// Primary
$primary__color: hsl(0, 0%, 50%);
$primary__text: hsla(0, 100%, 100%, 0.8);

// Accent
$accent__color: hsl(0, 0%, 20%);
$accent__text: hsla(0, 100%, 100%, 0.8);

// Info
$info__color: #2196f3;
$info__text: #2196f3;

// Success
$success__color: #5ab517;
$success__text: #5ab517;

// Warn
$warn__color: #ff9800;
$warn__text: #ff9800;

// Error
$error__color: #f44336;
$error__text: #f44336;

$label-opacity: 0.7;
$at-backdrop__opacity: 0.8;

$divider: rgba(0, 0, 0, .05);

$font-family: 'Roboto', "Roboto2", sans-serif;
$font-color: rgba(0, 0, 0, 0.8);
$font-light: rgba(0, 0, 0, 0.5);
$font-color2: lighten($font-color, 20);
$font-size: 14px;
$font-size-small: 11px;
$font-size-large: 16px;
$layout-background: #f5f5f5;
//#F5F5F5;
//#E8E8E8;
$speed-fast: 100ms;
$speed-med: 250ms;
$speed-slow: 500ms;
$size-sm: 600px;
$size-md: 960px;
$size-lg: 1200px;

//Z-Index
$z-page: 1;
$z-notif: 4;
$z-toolbars: 2;
$z-topbar: 2;
$z-loadbar: 4;
$z-cover: 3;
$z-dialog: 3;

//Paper element
$paper-background-color: #fdfdfd;
$paper-border-color: #dddddd;

//Form
$control-border-color: #dddddd;

//UI
$ui-scrollbar-width: 8px;

//Table
$table-color: #dddddd;

$toolbar-border-inactive: darken($table-color, 10);
$toolbar-bgr-inactive: lighten($table-color, 5);
//Active
$toolbar-border-active: darken($table-color, 20);
$toolbar-bgr-active: lighten($table-color, 10);
//Hover
$toolbar-border-hover: darken($table-color, 30);
//Header
$table-header-border: $toolbar-border-inactive;
$table-header-bgr: $table-color;
$table-outline: $toolbar-border-hover;

@mixin placeholder($color: inherit, $opacity: 0.5, $style: normal, $size: $font-size, $weight: normal) {
  &::-webkit-input-placeholder {
    font-family: $font-family;
    font-style: $style;
    font-size: $size;
    font-weight: $weight;
    color: $color;
    opacity: $opacity;
  }

  &:-moz-placeholder {
    font-family: $font-family;
    font-style: $style;
    font-size: $size;
    font-weight: $weight;
    color: $color;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    font-family: $font-family;
    font-style: $style;
    font-size: $size;
    font-weight: $weight;
    color: $color;
    opacity: $opacity;
  }
}

@mixin whiteframe() {
  box-shadow: 0 -1px 0 var(--divider, $divider), 0 1px 0 var(--divider, $divider), 0 2px 6px rgba(0, 0, 0, .1);
}

@mixin nobreak() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin scrollbar($width: 10px, $background: #999999, $track: #888888, $track-hover: var(--accent__color, $accent__color)) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-track {
    background: $background;
    //box-shadow : 0 0 5px rgba(0, 0, 0, 0.15) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: $track;
    //border-radius : 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $track-hover;
    //box-shadow(0 0 0 3px $track-hover);
  }

}
