@import "../config";

.--text-bold {
  font-weight: 700 !important;
}

.--text-small {
  font-size: 12px !important;
}

.--text-deleted {
  text-decoration: line-through !important;
}

.--text-light {
  opacity: $label-opacity !important;
}

.--text-primary {
  color: var(--accent__color, $accent__color) !important;
}

.--text-error {
  color: var(--error__color, $error__color) !important;
}

.--text-info {
  color: var(--info__color, $info__color) !important;
}

.--text-success {
  color: var(--success__color, $success__color) !important;
}

.--text-warn {
  color: var(--warn__color, $warn__color) !important;
}

.--text-clip {
  display: block;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.--text-transform-uppercase {
  text-transform: uppercase;
}

.--text-transform-capitalize {
  text-transform: capitalize;
}

.--text-transform-lowercase {
  text-transform: lowercase;
}
