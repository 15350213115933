/* You can add global styles to this file, and also import other style files */
$color-principal: #27A4F2;
$color-secundario: #49C2F2;
$color-alternativo: #F2B927;
$color-oscuro: #0a0f2a;
$color-claro: #ffff;
$color-gris: #727272;
$color-gris-claro: #e3e3e3;
$fuente-principal: Quicksand-Regular;
$fuente-principal-bold: Quicksand-Bold;
$fuente-Titulos: BeVietnam-Bold;
