.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-start {
  align-items: flex-end;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}
