.--cursor-default {
  cursor: default !important;
}

.--cursor-disabled {
  cursor: not-allowed !important;
}

.--cursor-help {
  cursor: help !important;
}

.--cursor-move {
  cursor: move !important;
}

.--cursor-loading {
  cursor: wait !important;
}

.--cursor-pointer {
  cursor: pointer !important;
}
