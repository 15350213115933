.at-modal {
  background: rgba(0, 0, 0, 0.5);
  animation: at-modal--enter 250ms;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  overscroll-behavior: none;
}

@keyframes at-modal--enter {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.5);
  }
}
