@import "../config";

.padding-xs {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.padding-sm {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.padding-md {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.padding-lg {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.padding-xl {
  padding-left: 96px !important;
  padding-right: 96px !important;
}
