@import "config";
/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/
@-moz-document url-prefix() {
  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}

/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */
.flex-order {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  order: 0;
}

.flex-order--20 {
  -webkit-box-ordinal-group: -19;
  -webkit-order: -20;
  order: -20;
}

.flex-order--19 {
  -webkit-box-ordinal-group: -18;
  -webkit-order: -19;
  order: -19;
}

.flex-order--18 {
  -webkit-box-ordinal-group: -17;
  -webkit-order: -18;
  order: -18;
}

.flex-order--17 {
  -webkit-box-ordinal-group: -16;
  -webkit-order: -17;
  order: -17;
}

.flex-order--16 {
  -webkit-box-ordinal-group: -15;
  -webkit-order: -16;
  order: -16;
}

.flex-order--15 {
  -webkit-box-ordinal-group: -14;
  -webkit-order: -15;
  order: -15;
}

.flex-order--14 {
  -webkit-box-ordinal-group: -13;
  -webkit-order: -14;
  order: -14;
}

.flex-order--13 {
  -webkit-box-ordinal-group: -12;
  -webkit-order: -13;
  order: -13;
}

.flex-order--12 {
  -webkit-box-ordinal-group: -11;
  -webkit-order: -12;
  order: -12;
}

.flex-order--11 {
  -webkit-box-ordinal-group: -10;
  -webkit-order: -11;
  order: -11;
}

.flex-order--10 {
  -webkit-box-ordinal-group: -9;
  -webkit-order: -10;
  order: -10;
}

.flex-order--9 {
  -webkit-box-ordinal-group: -8;
  -webkit-order: -9;
  order: -9;
}

.flex-order--8 {
  -webkit-box-ordinal-group: -7;
  -webkit-order: -8;
  order: -8;
}

.flex-order--7 {
  -webkit-box-ordinal-group: -6;
  -webkit-order: -7;
  order: -7;
}

.flex-order--6 {
  -webkit-box-ordinal-group: -5;
  -webkit-order: -6;
  order: -6;
}

.flex-order--5 {
  -webkit-box-ordinal-group: -4;
  -webkit-order: -5;
  order: -5;
}

.flex-order--4 {
  -webkit-box-ordinal-group: -3;
  -webkit-order: -4;
  order: -4;
}

.flex-order--3 {
  -webkit-box-ordinal-group: -2;
  -webkit-order: -3;
  order: -3;
}

.flex-order--2 {
  -webkit-box-ordinal-group: -1;
  -webkit-order: -2;
  order: -2;
}

.flex-order--1 {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  order: -1;
}

.flex-order-0 {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  order: 0;
}

.flex-order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  order: 1;
}

.flex-order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  order: 2;
}

.flex-order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  order: 3;
}

.flex-order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
  order: 4;
}

.flex-order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
  order: 5;
}

.flex-order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
  order: 6;
}

.flex-order-7 {
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
  order: 7;
}

.flex-order-8 {
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
  order: 8;
}

.flex-order-9 {
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
  order: 9;
}

.flex-order-10 {
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
  order: 10;
}

.flex-order-11 {
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
  order: 11;
}

.flex-order-12 {
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
  order: 12;
}

.flex-order-13 {
  -webkit-box-ordinal-group: 14;
  -webkit-order: 13;
  order: 13;
}

.flex-order-14 {
  -webkit-box-ordinal-group: 15;
  -webkit-order: 14;
  order: 14;
}

.flex-order-15 {
  -webkit-box-ordinal-group: 16;
  -webkit-order: 15;
  order: 15;
}

.flex-order-16 {
  -webkit-box-ordinal-group: 17;
  -webkit-order: 16;
  order: 16;
}

.flex-order-17 {
  -webkit-box-ordinal-group: 18;
  -webkit-order: 17;
  order: 17;
}

.flex-order-18 {
  -webkit-box-ordinal-group: 19;
  -webkit-order: 18;
  order: 18;
}

.flex-order-19 {
  -webkit-box-ordinal-group: 20;
  -webkit-order: 19;
  order: 19;
}

.flex-order-20 {
  -webkit-box-ordinal-group: 21;
  -webkit-order: 20;
  order: 20;
}

.offset-0, .flex-offset-0 {
  margin-left: 0;
}

[dir=rtl] .offset-0, [dir=rtl] .flex-offset-0 {
  margin-left: auto;
  margin-right: 0;
}

.offset-5, .flex-offset-5 {
  margin-left: 5%;
}

[dir=rtl] .offset-5, [dir=rtl] .flex-offset-5 {
  margin-left: auto;
  margin-right: 5%;
}

.offset-10, .flex-offset-10 {
  margin-left: 10%;
}

[dir=rtl] .offset-10, [dir=rtl] .flex-offset-10 {
  margin-left: auto;
  margin-right: 10%;
}

.offset-15, .flex-offset-15 {
  margin-left: 15%;
}

[dir=rtl] .offset-15, [dir=rtl] .flex-offset-15 {
  margin-left: auto;
  margin-right: 15%;
}

.offset-20, .flex-offset-20 {
  margin-left: 20%;
}

[dir=rtl] .offset-20, [dir=rtl] .flex-offset-20 {
  margin-left: auto;
  margin-right: 20%;
}

.offset-25, .flex-offset-25 {
  margin-left: 25%;
}

[dir=rtl] .offset-25, [dir=rtl] .flex-offset-25 {
  margin-left: auto;
  margin-right: 25%;
}

.offset-30, .flex-offset-30 {
  margin-left: 30%;
}

[dir=rtl] .offset-30, [dir=rtl] .flex-offset-30 {
  margin-left: auto;
  margin-right: 30%;
}

.offset-35, .flex-offset-35 {
  margin-left: 35%;
}

[dir=rtl] .offset-35, [dir=rtl] .flex-offset-35 {
  margin-left: auto;
  margin-right: 35%;
}

.offset-40, .flex-offset-40 {
  margin-left: 40%;
}

[dir=rtl] .offset-40, [dir=rtl] .flex-offset-40 {
  margin-left: auto;
  margin-right: 40%;
}

.offset-45, .flex-offset-45 {
  margin-left: 45%;
}

[dir=rtl] .offset-45, [dir=rtl] .flex-offset-45 {
  margin-left: auto;
  margin-right: 45%;
}

.offset-50, .flex-offset-50 {
  margin-left: 50%;
}

[dir=rtl] .offset-50, [dir=rtl] .flex-offset-50 {
  margin-left: auto;
  margin-right: 50%;
}

.offset-55, .flex-offset-55 {
  margin-left: 55%;
}

[dir=rtl] .offset-55, [dir=rtl] .flex-offset-55 {
  margin-left: auto;
  margin-right: 55%;
}

.offset-60, .flex-offset-60 {
  margin-left: 60%;
}

[dir=rtl] .offset-60, [dir=rtl] .flex-offset-60 {
  margin-left: auto;
  margin-right: 60%;
}

.offset-65, .flex-offset-65 {
  margin-left: 65%;
}

[dir=rtl] .offset-65, [dir=rtl] .flex-offset-65 {
  margin-left: auto;
  margin-right: 65%;
}

.offset-70, .flex-offset-70 {
  margin-left: 70%;
}

[dir=rtl] .offset-70, [dir=rtl] .flex-offset-70 {
  margin-left: auto;
  margin-right: 70%;
}

.offset-75, .flex-offset-75 {
  margin-left: 75%;
}

[dir=rtl] .offset-75, [dir=rtl] .flex-offset-75 {
  margin-left: auto;
  margin-right: 75%;
}

.offset-80, .flex-offset-80 {
  margin-left: 80%;
}

[dir=rtl] .offset-80, [dir=rtl] .flex-offset-80 {
  margin-left: auto;
  margin-right: 80%;
}

.offset-85, .flex-offset-85 {
  margin-left: 85%;
}

[dir=rtl] .offset-85, [dir=rtl] .flex-offset-85 {
  margin-left: auto;
  margin-right: 85%;
}

.offset-90, .flex-offset-90 {
  margin-left: 90%;
}

[dir=rtl] .offset-90, [dir=rtl] .flex-offset-90 {
  margin-left: auto;
  margin-right: 90%;
}

.offset-95, .flex-offset-95 {
  margin-left: 95%;
}

[dir=rtl] .offset-95, [dir=rtl] .flex-offset-95 {
  margin-left: auto;
  margin-right: 95%;
}

.offset-33, .flex-offset-33 {
  margin-left: calc(100% / 3);
}

.offset-66, .flex-offset-66 {
  margin-left: calc(200% / 3);
}

[dir=rtl] .offset-66, [dir=rtl] .flex-offset-66 {
  margin-left: auto;
  margin-right: calc(200% / 3);
}

.layout-align,
.layout-align-start-stretch {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-grid-row-align: stretch;
  align-items: stretch;
}

.layout-align-start,
.layout-align-start-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-stretch {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-stretch {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.layout-align-end,
.layout-align-end-start,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-stretch {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-start,
.layout-align-space-around-end,
.layout-align-space-around-stretch {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-start,
.layout-align-space-between-end,
.layout-align-space-between-stretch {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.layout-align-start-start,
.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-between-start,
.layout-align-space-around-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.layout-align-start-center,
.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  max-width: 100%;
}

.layout-align-start-center > *,
.layout-align-center-center > *,
.layout-align-end-center > *,
.layout-align-space-between-center > *,
.layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box;
}

.layout-align-start-end,
.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-between-end,
.layout-align-space-around-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}

.layout-align-start-stretch,
.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-between-stretch,
.layout-align-space-around-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-grid-row-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  align-content: stretch;
}


.flex-grow {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  box-sizing: border-box;
}

.flex-initial {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  box-sizing: border-box;
}

.flex-auto {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  box-sizing: border-box;
}

.flex-none {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  box-sizing: border-box;
}

.flex-noshrink {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
  box-sizing: border-box;
}

.flex-nogrow {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  box-sizing: border-box;
}

.flex-0 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  max-width: 0;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-0 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  max-width: 0;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.layout-column > .flex-0 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  max-width: 100%;
  max-height: 0;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-0 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  max-width: 0;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.layout-column > .flex-0 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  max-width: 100%;
  max-height: 0;
  box-sizing: border-box;
  min-height: 0;
}

.flex-5 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-5 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-5 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-5 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-5 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box;
}

.flex-10 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-10 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-10 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-10 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-10 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box;
}

.flex-15 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-15 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-15 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-15 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-15 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box;
}

.flex-20 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-20 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-20 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-20 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-20 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box;
}

.flex-25 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-25 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-25 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-25 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-25 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box;
}

.flex-30 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-30 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-30 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-30 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-30 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box;
}

.flex-35 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-35 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-35 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-35 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-35 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box;
}

.flex-40 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-40 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-40 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-40 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-40 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box;
}

.flex-45 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-45 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-45 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-45 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-45 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box;
}

.flex-50 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-50 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-50 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-50 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-50 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box;
}

.flex-55 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-55 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-55 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-55 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-55 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box;
}

.flex-60 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-60 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-60 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-60 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-60 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box;
}

.flex-65 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-65 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-65 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-65 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-65 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box;
}

.flex-70 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-70 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-70 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-70 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-70 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box;
}

.flex-75 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-75 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-75 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-75 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-75 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box;
}

.flex-80 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-80 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-80 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-80 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-80 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box;
}

.flex-85 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-85 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-85 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-85 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-85 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box;
}

.flex-90 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-90 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-90 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-90 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-90 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box;
}

.flex-95 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-95 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-95 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-95 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-95 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box;
}

.flex-100 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-100 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-100 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-row > .flex-100 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-column > .flex-100 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-33, .layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex-66, .layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout-row > .flex {
  min-width: 0;
}

.layout-column > .flex-33, .layout-column > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.layout-column > .flex-66, .layout-column > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.layout-column > .flex {
  min-height: 0;
}

.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.layout-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.layout-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.layout-padding-sm > *,
.layout-padding > .flex-sm {
  padding: 4px;
}

.layout-padding,
.layout-padding-gt-sm,
.layout-padding-md,
.layout-padding > *,
.layout-padding-gt-sm > *,
.layout-padding-md > *,
.layout-padding > .flex,
.layout-padding > .flex-gt-sm,
.layout-padding > .flex-md {
  padding: 8px;
}

.layout-padding-gt-md > *,
.layout-padding-lg > *,
.layout-padding-gt-lg > *,
.layout-padding > .flex-gt-md,
.layout-padding > .flex-lg,
.layout-padding > .flex-lg,
.layout-padding > .flex-gt-lg {
  padding: 16px;
}

.layout-margin-sm > *,
.layout-margin > .flex-sm {
  margin: 4px;
}

.layout-margin,
.layout-margin-gt-sm,
.layout-margin-md,
.layout-margin > *,
.layout-margin-gt-sm > *,
.layout-margin-md > *,
.layout-margin > .flex,
.layout-margin > .flex-gt-sm,
.layout-margin > .flex-md {
  margin: 8px;
}

.layout-margin-gt-md > *,
.layout-margin-lg > *,
.layout-margin-gt-lg > *,
.layout-margin > .flex-gt-md,
.layout-margin > .flex-lg,
.layout-margin > .flex-gt-lg {
  margin: 16px;
}

.layout-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.layout-nowrap {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  .hide-xs:not(.show-xs):not(.show), .hide:not(.show-xs):not(.show) {
    display: none !important;
  }

  .flex-order-xs--20 {
    -webkit-box-ordinal-group: -19;
    -webkit-order: -20;
    order: -20;
  }

  .flex-order-xs--19 {
    -webkit-box-ordinal-group: -18;
    -webkit-order: -19;
    order: -19;
  }

  .flex-order-xs--18 {
    -webkit-box-ordinal-group: -17;
    -webkit-order: -18;
    order: -18;
  }

  .flex-order-xs--17 {
    -webkit-box-ordinal-group: -16;
    -webkit-order: -17;
    order: -17;
  }

  .flex-order-xs--16 {
    -webkit-box-ordinal-group: -15;
    -webkit-order: -16;
    order: -16;
  }

  .flex-order-xs--15 {
    -webkit-box-ordinal-group: -14;
    -webkit-order: -15;
    order: -15;
  }

  .flex-order-xs--14 {
    -webkit-box-ordinal-group: -13;
    -webkit-order: -14;
    order: -14;
  }

  .flex-order-xs--13 {
    -webkit-box-ordinal-group: -12;
    -webkit-order: -13;
    order: -13;
  }

  .flex-order-xs--12 {
    -webkit-box-ordinal-group: -11;
    -webkit-order: -12;
    order: -12;
  }

  .flex-order-xs--11 {
    -webkit-box-ordinal-group: -10;
    -webkit-order: -11;
    order: -11;
  }

  .flex-order-xs--10 {
    -webkit-box-ordinal-group: -9;
    -webkit-order: -10;
    order: -10;
  }

  .flex-order-xs--9 {
    -webkit-box-ordinal-group: -8;
    -webkit-order: -9;
    order: -9;
  }

  .flex-order-xs--8 {
    -webkit-box-ordinal-group: -7;
    -webkit-order: -8;
    order: -8;
  }

  .flex-order-xs--7 {
    -webkit-box-ordinal-group: -6;
    -webkit-order: -7;
    order: -7;
  }

  .flex-order-xs--6 {
    -webkit-box-ordinal-group: -5;
    -webkit-order: -6;
    order: -6;
  }

  .flex-order-xs--5 {
    -webkit-box-ordinal-group: -4;
    -webkit-order: -5;
    order: -5;
  }

  .flex-order-xs--4 {
    -webkit-box-ordinal-group: -3;
    -webkit-order: -4;
    order: -4;
  }

  .flex-order-xs--3 {
    -webkit-box-ordinal-group: -2;
    -webkit-order: -3;
    order: -3;
  }

  .flex-order-xs--2 {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -2;
    order: -2;
  }

  .flex-order-xs--1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    order: -1;
  }

  .flex-order-xs-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
  }

  .flex-order-xs-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
  }

  .flex-order-xs-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }

  .flex-order-xs-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
  }

  .flex-order-xs-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    order: 4;
  }

  .flex-order-xs-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    order: 5;
  }

  .flex-order-xs-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    order: 6;
  }

  .flex-order-xs-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    order: 7;
  }

  .flex-order-xs-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    order: 8;
  }

  .flex-order-xs-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    order: 9;
  }

  .flex-order-xs-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    order: 10;
  }

  .flex-order-xs-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    order: 11;
  }

  .flex-order-xs-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    order: 12;
  }

  .flex-order-xs-13 {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    order: 13;
  }

  .flex-order-xs-14 {
    -webkit-box-ordinal-group: 15;
    -webkit-order: 14;
    order: 14;
  }

  .flex-order-xs-15 {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    order: 15;
  }

  .flex-order-xs-16 {
    -webkit-box-ordinal-group: 17;
    -webkit-order: 16;
    order: 16;
  }

  .flex-order-xs-17 {
    -webkit-box-ordinal-group: 18;
    -webkit-order: 17;
    order: 17;
  }

  .flex-order-xs-18 {
    -webkit-box-ordinal-group: 19;
    -webkit-order: 18;
    order: 18;
  }

  .flex-order-xs-19 {
    -webkit-box-ordinal-group: 20;
    -webkit-order: 19;
    order: 19;
  }

  .flex-order-xs-20 {
    -webkit-box-ordinal-group: 21;
    -webkit-order: 20;
    order: 20;
  }

  .offset-xs-0, .flex-offset-xs-0 {
    margin-left: 0;
  }

  [dir=rtl] .offset-xs-0, [dir=rtl] .flex-offset-xs-0 {
    margin-left: auto;
    margin-right: 0;
  }

  .offset-xs-5, .flex-offset-xs-5 {
    margin-left: 5%;
  }

  [dir=rtl] .offset-xs-5, [dir=rtl] .flex-offset-xs-5 {
    margin-left: auto;
    margin-right: 5%;
  }

  .offset-xs-10, .flex-offset-xs-10 {
    margin-left: 10%;
  }

  [dir=rtl] .offset-xs-10, [dir=rtl] .flex-offset-xs-10 {
    margin-left: auto;
    margin-right: 10%;
  }

  .offset-xs-15, .flex-offset-xs-15 {
    margin-left: 15%;
  }

  [dir=rtl] .offset-xs-15, [dir=rtl] .flex-offset-xs-15 {
    margin-left: auto;
    margin-right: 15%;
  }

  .offset-xs-20, .flex-offset-xs-20 {
    margin-left: 20%;
  }

  [dir=rtl] .offset-xs-20, [dir=rtl] .flex-offset-xs-20 {
    margin-left: auto;
    margin-right: 20%;
  }

  .offset-xs-25, .flex-offset-xs-25 {
    margin-left: 25%;
  }

  [dir=rtl] .offset-xs-25, [dir=rtl] .flex-offset-xs-25 {
    margin-left: auto;
    margin-right: 25%;
  }

  .offset-xs-30, .flex-offset-xs-30 {
    margin-left: 30%;
  }

  [dir=rtl] .offset-xs-30, [dir=rtl] .flex-offset-xs-30 {
    margin-left: auto;
    margin-right: 30%;
  }

  .offset-xs-35, .flex-offset-xs-35 {
    margin-left: 35%;
  }

  [dir=rtl] .offset-xs-35, [dir=rtl] .flex-offset-xs-35 {
    margin-left: auto;
    margin-right: 35%;
  }

  .offset-xs-40, .flex-offset-xs-40 {
    margin-left: 40%;
  }

  [dir=rtl] .offset-xs-40, [dir=rtl] .flex-offset-xs-40 {
    margin-left: auto;
    margin-right: 40%;
  }

  .offset-xs-45, .flex-offset-xs-45 {
    margin-left: 45%;
  }

  [dir=rtl] .offset-xs-45, [dir=rtl] .flex-offset-xs-45 {
    margin-left: auto;
    margin-right: 45%;
  }

  .offset-xs-50, .flex-offset-xs-50 {
    margin-left: 50%;
  }

  [dir=rtl] .offset-xs-50, [dir=rtl] .flex-offset-xs-50 {
    margin-left: auto;
    margin-right: 50%;
  }

  .offset-xs-55, .flex-offset-xs-55 {
    margin-left: 55%;
  }

  [dir=rtl] .offset-xs-55, [dir=rtl] .flex-offset-xs-55 {
    margin-left: auto;
    margin-right: 55%;
  }

  .offset-xs-60, .flex-offset-xs-60 {
    margin-left: 60%;
  }

  [dir=rtl] .offset-xs-60, [dir=rtl] .flex-offset-xs-60 {
    margin-left: auto;
    margin-right: 60%;
  }

  .offset-xs-65, .flex-offset-xs-65 {
    margin-left: 65%;
  }

  [dir=rtl] .offset-xs-65, [dir=rtl] .flex-offset-xs-65 {
    margin-left: auto;
    margin-right: 65%;
  }

  .offset-xs-70, .flex-offset-xs-70 {
    margin-left: 70%;
  }

  [dir=rtl] .offset-xs-70, [dir=rtl] .flex-offset-xs-70 {
    margin-left: auto;
    margin-right: 70%;
  }

  .offset-xs-75, .flex-offset-xs-75 {
    margin-left: 75%;
  }

  [dir=rtl] .offset-xs-75, [dir=rtl] .flex-offset-xs-75 {
    margin-left: auto;
    margin-right: 75%;
  }

  .offset-xs-80, .flex-offset-xs-80 {
    margin-left: 80%;
  }

  [dir=rtl] .offset-xs-80, [dir=rtl] .flex-offset-xs-80 {
    margin-left: auto;
    margin-right: 80%;
  }

  .offset-xs-85, .flex-offset-xs-85 {
    margin-left: 85%;
  }

  [dir=rtl] .offset-xs-85, [dir=rtl] .flex-offset-xs-85 {
    margin-left: auto;
    margin-right: 85%;
  }

  .offset-xs-90, .flex-offset-xs-90 {
    margin-left: 90%;
  }

  [dir=rtl] .offset-xs-90, [dir=rtl] .flex-offset-xs-90 {
    margin-left: auto;
    margin-right: 90%;
  }

  .offset-xs-95, .flex-offset-xs-95 {
    margin-left: 95%;
  }

  [dir=rtl] .offset-xs-95, [dir=rtl] .flex-offset-xs-95 {
    margin-left: auto;
    margin-right: 95%;
  }

  .offset-xs-33, .flex-offset-xs-33 {
    margin-left: calc(100% / 3);
  }

  .offset-xs-66, .flex-offset-xs-66 {
    margin-left: calc(200% / 3);
  }

  [dir=rtl] .offset-xs-66, [dir=rtl] .flex-offset-xs-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }

  .layout-align-xs,
  .layout-align-xs-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }

  .layout-align-xs-start,
  .layout-align-xs-start-start,
  .layout-align-xs-start-center,
  .layout-align-xs-start-end,
  .layout-align-xs-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-xs-center,
  .layout-align-xs-center-start,
  .layout-align-xs-center-center,
  .layout-align-xs-center-end,
  .layout-align-xs-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-xs-end,
  .layout-align-xs-end-start,
  .layout-align-xs-end-center,
  .layout-align-xs-end-end,
  .layout-align-xs-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-xs-space-around,
  .layout-align-xs-space-around-center,
  .layout-align-xs-space-around-start,
  .layout-align-xs-space-around-end,
  .layout-align-xs-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-xs-space-between,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-xs-start-start,
  .layout-align-xs-center-start,
  .layout-align-xs-end-start,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-around-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-xs-start-center,
  .layout-align-xs-center-center,
  .layout-align-xs-end-center,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-around-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-xs-start-center > *,
  .layout-align-xs-center-center > *,
  .layout-align-xs-end-center > *,
  .layout-align-xs-space-between-center > *,
  .layout-align-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-xs-start-end,
  .layout-align-xs-center-end,
  .layout-align-xs-end-end,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-around-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-xs-start-stretch,
  .layout-align-xs-center-stretch,
  .layout-align-xs-end-stretch,
  .layout-align-xs-space-between-stretch,
  .layout-align-xs-space-around-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-xs {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }

  .flex-xs-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-xs-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-xs-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-xs-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-xs-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-xs-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-column > .flex-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-xs-column > .flex-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .flex-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .flex-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .flex-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .flex-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .flex-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .flex-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .flex-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .flex-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .flex-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .flex-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .flex-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .flex-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .flex-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .flex-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .flex-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .flex-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .flex-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .flex-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .flex-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .flex-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-33, .layout-xs-row > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-66, .layout-xs-row > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex {
    min-width: 0;
  }

  .layout-xs-column > .flex-xs-33, .layout-xs-column > .flex-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex-xs-66, .layout-xs-column > .flex-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xs-column > .flex {
    min-height: 0;
  }

  .layout-xs, .layout-xs-column, .layout-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }

  .layout-xs-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .layout-xs-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 600px) {
  .flex-order-gt-xs--20 {
    -webkit-box-ordinal-group: -19;
    -webkit-order: -20;
    order: -20;
  }

  .flex-order-gt-xs--19 {
    -webkit-box-ordinal-group: -18;
    -webkit-order: -19;
    order: -19;
  }

  .flex-order-gt-xs--18 {
    -webkit-box-ordinal-group: -17;
    -webkit-order: -18;
    order: -18;
  }

  .flex-order-gt-xs--17 {
    -webkit-box-ordinal-group: -16;
    -webkit-order: -17;
    order: -17;
  }

  .flex-order-gt-xs--16 {
    -webkit-box-ordinal-group: -15;
    -webkit-order: -16;
    order: -16;
  }

  .flex-order-gt-xs--15 {
    -webkit-box-ordinal-group: -14;
    -webkit-order: -15;
    order: -15;
  }

  .flex-order-gt-xs--14 {
    -webkit-box-ordinal-group: -13;
    -webkit-order: -14;
    order: -14;
  }

  .flex-order-gt-xs--13 {
    -webkit-box-ordinal-group: -12;
    -webkit-order: -13;
    order: -13;
  }

  .flex-order-gt-xs--12 {
    -webkit-box-ordinal-group: -11;
    -webkit-order: -12;
    order: -12;
  }

  .flex-order-gt-xs--11 {
    -webkit-box-ordinal-group: -10;
    -webkit-order: -11;
    order: -11;
  }

  .flex-order-gt-xs--10 {
    -webkit-box-ordinal-group: -9;
    -webkit-order: -10;
    order: -10;
  }

  .flex-order-gt-xs--9 {
    -webkit-box-ordinal-group: -8;
    -webkit-order: -9;
    order: -9;
  }

  .flex-order-gt-xs--8 {
    -webkit-box-ordinal-group: -7;
    -webkit-order: -8;
    order: -8;
  }

  .flex-order-gt-xs--7 {
    -webkit-box-ordinal-group: -6;
    -webkit-order: -7;
    order: -7;
  }

  .flex-order-gt-xs--6 {
    -webkit-box-ordinal-group: -5;
    -webkit-order: -6;
    order: -6;
  }

  .flex-order-gt-xs--5 {
    -webkit-box-ordinal-group: -4;
    -webkit-order: -5;
    order: -5;
  }

  .flex-order-gt-xs--4 {
    -webkit-box-ordinal-group: -3;
    -webkit-order: -4;
    order: -4;
  }

  .flex-order-gt-xs--3 {
    -webkit-box-ordinal-group: -2;
    -webkit-order: -3;
    order: -3;
  }

  .flex-order-gt-xs--2 {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -2;
    order: -2;
  }

  .flex-order-gt-xs--1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    order: -1;
  }

  .flex-order-gt-xs-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
  }

  .flex-order-gt-xs-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
  }

  .flex-order-gt-xs-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }

  .flex-order-gt-xs-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
  }

  .flex-order-gt-xs-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    order: 4;
  }

  .flex-order-gt-xs-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    order: 5;
  }

  .flex-order-gt-xs-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    order: 6;
  }

  .flex-order-gt-xs-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    order: 7;
  }

  .flex-order-gt-xs-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    order: 8;
  }

  .flex-order-gt-xs-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    order: 9;
  }

  .flex-order-gt-xs-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    order: 10;
  }

  .flex-order-gt-xs-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    order: 11;
  }

  .flex-order-gt-xs-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    order: 12;
  }

  .flex-order-gt-xs-13 {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    order: 13;
  }

  .flex-order-gt-xs-14 {
    -webkit-box-ordinal-group: 15;
    -webkit-order: 14;
    order: 14;
  }

  .flex-order-gt-xs-15 {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    order: 15;
  }

  .flex-order-gt-xs-16 {
    -webkit-box-ordinal-group: 17;
    -webkit-order: 16;
    order: 16;
  }

  .flex-order-gt-xs-17 {
    -webkit-box-ordinal-group: 18;
    -webkit-order: 17;
    order: 17;
  }

  .flex-order-gt-xs-18 {
    -webkit-box-ordinal-group: 19;
    -webkit-order: 18;
    order: 18;
  }

  .flex-order-gt-xs-19 {
    -webkit-box-ordinal-group: 20;
    -webkit-order: 19;
    order: 19;
  }

  .flex-order-gt-xs-20 {
    -webkit-box-ordinal-group: 21;
    -webkit-order: 20;
    order: 20;
  }

  .offset-gt-xs-0, .flex-offset-gt-xs-0 {
    margin-left: 0;
  }

  [dir=rtl] .offset-gt-xs-0, [dir=rtl] .flex-offset-gt-xs-0 {
    margin-left: auto;
    margin-right: 0;
  }

  .offset-gt-xs-5, .flex-offset-gt-xs-5 {
    margin-left: 5%;
  }

  [dir=rtl] .offset-gt-xs-5, [dir=rtl] .flex-offset-gt-xs-5 {
    margin-left: auto;
    margin-right: 5%;
  }

  .offset-gt-xs-10, .flex-offset-gt-xs-10 {
    margin-left: 10%;
  }

  [dir=rtl] .offset-gt-xs-10, [dir=rtl] .flex-offset-gt-xs-10 {
    margin-left: auto;
    margin-right: 10%;
  }

  .offset-gt-xs-15, .flex-offset-gt-xs-15 {
    margin-left: 15%;
  }

  [dir=rtl] .offset-gt-xs-15, [dir=rtl] .flex-offset-gt-xs-15 {
    margin-left: auto;
    margin-right: 15%;
  }

  .offset-gt-xs-20, .flex-offset-gt-xs-20 {
    margin-left: 20%;
  }

  [dir=rtl] .offset-gt-xs-20, [dir=rtl] .flex-offset-gt-xs-20 {
    margin-left: auto;
    margin-right: 20%;
  }

  .offset-gt-xs-25, .flex-offset-gt-xs-25 {
    margin-left: 25%;
  }

  [dir=rtl] .offset-gt-xs-25, [dir=rtl] .flex-offset-gt-xs-25 {
    margin-left: auto;
    margin-right: 25%;
  }

  .offset-gt-xs-30, .flex-offset-gt-xs-30 {
    margin-left: 30%;
  }

  [dir=rtl] .offset-gt-xs-30, [dir=rtl] .flex-offset-gt-xs-30 {
    margin-left: auto;
    margin-right: 30%;
  }

  .offset-gt-xs-35, .flex-offset-gt-xs-35 {
    margin-left: 35%;
  }

  [dir=rtl] .offset-gt-xs-35, [dir=rtl] .flex-offset-gt-xs-35 {
    margin-left: auto;
    margin-right: 35%;
  }

  .offset-gt-xs-40, .flex-offset-gt-xs-40 {
    margin-left: 40%;
  }

  [dir=rtl] .offset-gt-xs-40, [dir=rtl] .flex-offset-gt-xs-40 {
    margin-left: auto;
    margin-right: 40%;
  }

  .offset-gt-xs-45, .flex-offset-gt-xs-45 {
    margin-left: 45%;
  }

  [dir=rtl] .offset-gt-xs-45, [dir=rtl] .flex-offset-gt-xs-45 {
    margin-left: auto;
    margin-right: 45%;
  }

  .offset-gt-xs-50, .flex-offset-gt-xs-50 {
    margin-left: 50%;
  }

  [dir=rtl] .offset-gt-xs-50, [dir=rtl] .flex-offset-gt-xs-50 {
    margin-left: auto;
    margin-right: 50%;
  }

  .offset-gt-xs-55, .flex-offset-gt-xs-55 {
    margin-left: 55%;
  }

  [dir=rtl] .offset-gt-xs-55, [dir=rtl] .flex-offset-gt-xs-55 {
    margin-left: auto;
    margin-right: 55%;
  }

  .offset-gt-xs-60, .flex-offset-gt-xs-60 {
    margin-left: 60%;
  }

  [dir=rtl] .offset-gt-xs-60, [dir=rtl] .flex-offset-gt-xs-60 {
    margin-left: auto;
    margin-right: 60%;
  }

  .offset-gt-xs-65, .flex-offset-gt-xs-65 {
    margin-left: 65%;
  }

  [dir=rtl] .offset-gt-xs-65, [dir=rtl] .flex-offset-gt-xs-65 {
    margin-left: auto;
    margin-right: 65%;
  }

  .offset-gt-xs-70, .flex-offset-gt-xs-70 {
    margin-left: 70%;
  }

  [dir=rtl] .offset-gt-xs-70, [dir=rtl] .flex-offset-gt-xs-70 {
    margin-left: auto;
    margin-right: 70%;
  }

  .offset-gt-xs-75, .flex-offset-gt-xs-75 {
    margin-left: 75%;
  }

  [dir=rtl] .offset-gt-xs-75, [dir=rtl] .flex-offset-gt-xs-75 {
    margin-left: auto;
    margin-right: 75%;
  }

  .offset-gt-xs-80, .flex-offset-gt-xs-80 {
    margin-left: 80%;
  }

  [dir=rtl] .offset-gt-xs-80, [dir=rtl] .flex-offset-gt-xs-80 {
    margin-left: auto;
    margin-right: 80%;
  }

  .offset-gt-xs-85, .flex-offset-gt-xs-85 {
    margin-left: 85%;
  }

  [dir=rtl] .offset-gt-xs-85, [dir=rtl] .flex-offset-gt-xs-85 {
    margin-left: auto;
    margin-right: 85%;
  }

  .offset-gt-xs-90, .flex-offset-gt-xs-90 {
    margin-left: 90%;
  }

  [dir=rtl] .offset-gt-xs-90, [dir=rtl] .flex-offset-gt-xs-90 {
    margin-left: auto;
    margin-right: 90%;
  }

  .offset-gt-xs-95, .flex-offset-gt-xs-95 {
    margin-left: 95%;
  }

  [dir=rtl] .offset-gt-xs-95, [dir=rtl] .flex-offset-gt-xs-95 {
    margin-left: auto;
    margin-right: 95%;
  }

  .offset-gt-xs-33, .flex-offset-gt-xs-33 {
    margin-left: calc(100% / 3);
  }

  .offset-gt-xs-66, .flex-offset-gt-xs-66 {
    margin-left: calc(200% / 3);
  }

  [dir=rtl] .offset-gt-xs-66, [dir=rtl] .flex-offset-gt-xs-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }

  .layout-align-gt-xs,
  .layout-align-gt-xs-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }

  .layout-align-gt-xs-start,
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-gt-xs-center,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-gt-xs-end,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-gt-xs-space-around,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-gt-xs-space-between,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-around-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-around-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-gt-xs-start-center > *,
  .layout-align-gt-xs-center-center > *,
  .layout-align-gt-xs-end-center > *,
  .layout-align-gt-xs-space-between-center > *,
  .layout-align-gt-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-around-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-gt-xs-start-stretch,
  .layout-align-gt-xs-center-stretch,
  .layout-align-gt-xs-end-stretch,
  .layout-align-gt-xs-space-between-stretch,
  .layout-align-gt-xs-space-around-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-gt-xs {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }

  .flex-gt-xs-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-column > .flex-gt-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-gt-xs-column > .flex-gt-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .flex-gt-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .flex-gt-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .flex-gt-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .flex-gt-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .flex-gt-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .flex-gt-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .flex-gt-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .flex-gt-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .flex-gt-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .flex-gt-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .flex-gt-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .flex-gt-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .flex-gt-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .flex-gt-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .flex-gt-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .flex-gt-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .flex-gt-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .flex-gt-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .flex-gt-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .flex-gt-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-66, .layout-gt-xs-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex {
    min-width: 0;
  }

  .layout-gt-xs-column > .flex-gt-xs-33, .layout-gt-xs-column > .flex-gt-xs-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex-gt-xs-66, .layout-gt-xs-column > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-xs-column > .flex {
    min-height: 0;
  }

  .layout-gt-xs, .layout-gt-xs-column, .layout-gt-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }

  .layout-gt-xs-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .layout-gt-xs-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .hide:not(.show-gt-xs):not(.show-sm):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none !important;
  }

  .hide-sm:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none !important;
  }

  .flex-order-sm--20 {
    -webkit-box-ordinal-group: -19;
    -webkit-order: -20;
    order: -20;
  }

  .flex-order-sm--19 {
    -webkit-box-ordinal-group: -18;
    -webkit-order: -19;
    order: -19;
  }

  .flex-order-sm--18 {
    -webkit-box-ordinal-group: -17;
    -webkit-order: -18;
    order: -18;
  }

  .flex-order-sm--17 {
    -webkit-box-ordinal-group: -16;
    -webkit-order: -17;
    order: -17;
  }

  .flex-order-sm--16 {
    -webkit-box-ordinal-group: -15;
    -webkit-order: -16;
    order: -16;
  }

  .flex-order-sm--15 {
    -webkit-box-ordinal-group: -14;
    -webkit-order: -15;
    order: -15;
  }

  .flex-order-sm--14 {
    -webkit-box-ordinal-group: -13;
    -webkit-order: -14;
    order: -14;
  }

  .flex-order-sm--13 {
    -webkit-box-ordinal-group: -12;
    -webkit-order: -13;
    order: -13;
  }

  .flex-order-sm--12 {
    -webkit-box-ordinal-group: -11;
    -webkit-order: -12;
    order: -12;
  }

  .flex-order-sm--11 {
    -webkit-box-ordinal-group: -10;
    -webkit-order: -11;
    order: -11;
  }

  .flex-order-sm--10 {
    -webkit-box-ordinal-group: -9;
    -webkit-order: -10;
    order: -10;
  }

  .flex-order-sm--9 {
    -webkit-box-ordinal-group: -8;
    -webkit-order: -9;
    order: -9;
  }

  .flex-order-sm--8 {
    -webkit-box-ordinal-group: -7;
    -webkit-order: -8;
    order: -8;
  }

  .flex-order-sm--7 {
    -webkit-box-ordinal-group: -6;
    -webkit-order: -7;
    order: -7;
  }

  .flex-order-sm--6 {
    -webkit-box-ordinal-group: -5;
    -webkit-order: -6;
    order: -6;
  }

  .flex-order-sm--5 {
    -webkit-box-ordinal-group: -4;
    -webkit-order: -5;
    order: -5;
  }

  .flex-order-sm--4 {
    -webkit-box-ordinal-group: -3;
    -webkit-order: -4;
    order: -4;
  }

  .flex-order-sm--3 {
    -webkit-box-ordinal-group: -2;
    -webkit-order: -3;
    order: -3;
  }

  .flex-order-sm--2 {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -2;
    order: -2;
  }

  .flex-order-sm--1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    order: -1;
  }

  .flex-order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
  }

  .flex-order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
  }

  .flex-order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }

  .flex-order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
  }

  .flex-order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    order: 4;
  }

  .flex-order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    order: 5;
  }

  .flex-order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    order: 6;
  }

  .flex-order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    order: 7;
  }

  .flex-order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    order: 8;
  }

  .flex-order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    order: 9;
  }

  .flex-order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    order: 10;
  }

  .flex-order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    order: 11;
  }

  .flex-order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    order: 12;
  }

  .flex-order-sm-13 {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    order: 13;
  }

  .flex-order-sm-14 {
    -webkit-box-ordinal-group: 15;
    -webkit-order: 14;
    order: 14;
  }

  .flex-order-sm-15 {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    order: 15;
  }

  .flex-order-sm-16 {
    -webkit-box-ordinal-group: 17;
    -webkit-order: 16;
    order: 16;
  }

  .flex-order-sm-17 {
    -webkit-box-ordinal-group: 18;
    -webkit-order: 17;
    order: 17;
  }

  .flex-order-sm-18 {
    -webkit-box-ordinal-group: 19;
    -webkit-order: 18;
    order: 18;
  }

  .flex-order-sm-19 {
    -webkit-box-ordinal-group: 20;
    -webkit-order: 19;
    order: 19;
  }

  .flex-order-sm-20 {
    -webkit-box-ordinal-group: 21;
    -webkit-order: 20;
    order: 20;
  }

  .offset-sm-0, .flex-offset-sm-0 {
    margin-left: 0;
  }

  [dir=rtl] .offset-sm-0, [dir=rtl] .flex-offset-sm-0 {
    margin-left: auto;
    margin-right: 0;
  }

  .offset-sm-5, .flex-offset-sm-5 {
    margin-left: 5%;
  }

  [dir=rtl] .offset-sm-5, [dir=rtl] .flex-offset-sm-5 {
    margin-left: auto;
    margin-right: 5%;
  }

  .offset-sm-10, .flex-offset-sm-10 {
    margin-left: 10%;
  }

  [dir=rtl] .offset-sm-10, [dir=rtl] .flex-offset-sm-10 {
    margin-left: auto;
    margin-right: 10%;
  }

  .offset-sm-15, .flex-offset-sm-15 {
    margin-left: 15%;
  }

  [dir=rtl] .offset-sm-15, [dir=rtl] .flex-offset-sm-15 {
    margin-left: auto;
    margin-right: 15%;
  }

  .offset-sm-20, .flex-offset-sm-20 {
    margin-left: 20%;
  }

  [dir=rtl] .offset-sm-20, [dir=rtl] .flex-offset-sm-20 {
    margin-left: auto;
    margin-right: 20%;
  }

  .offset-sm-25, .flex-offset-sm-25 {
    margin-left: 25%;
  }

  [dir=rtl] .offset-sm-25, [dir=rtl] .flex-offset-sm-25 {
    margin-left: auto;
    margin-right: 25%;
  }

  .offset-sm-30, .flex-offset-sm-30 {
    margin-left: 30%;
  }

  [dir=rtl] .offset-sm-30, [dir=rtl] .flex-offset-sm-30 {
    margin-left: auto;
    margin-right: 30%;
  }

  .offset-sm-35, .flex-offset-sm-35 {
    margin-left: 35%;
  }

  [dir=rtl] .offset-sm-35, [dir=rtl] .flex-offset-sm-35 {
    margin-left: auto;
    margin-right: 35%;
  }

  .offset-sm-40, .flex-offset-sm-40 {
    margin-left: 40%;
  }

  [dir=rtl] .offset-sm-40, [dir=rtl] .flex-offset-sm-40 {
    margin-left: auto;
    margin-right: 40%;
  }

  .offset-sm-45, .flex-offset-sm-45 {
    margin-left: 45%;
  }

  [dir=rtl] .offset-sm-45, [dir=rtl] .flex-offset-sm-45 {
    margin-left: auto;
    margin-right: 45%;
  }

  .offset-sm-50, .flex-offset-sm-50 {
    margin-left: 50%;
  }

  [dir=rtl] .offset-sm-50, [dir=rtl] .flex-offset-sm-50 {
    margin-left: auto;
    margin-right: 50%;
  }

  .offset-sm-55, .flex-offset-sm-55 {
    margin-left: 55%;
  }

  [dir=rtl] .offset-sm-55, [dir=rtl] .flex-offset-sm-55 {
    margin-left: auto;
    margin-right: 55%;
  }

  .offset-sm-60, .flex-offset-sm-60 {
    margin-left: 60%;
  }

  [dir=rtl] .offset-sm-60, [dir=rtl] .flex-offset-sm-60 {
    margin-left: auto;
    margin-right: 60%;
  }

  .offset-sm-65, .flex-offset-sm-65 {
    margin-left: 65%;
  }

  [dir=rtl] .offset-sm-65, [dir=rtl] .flex-offset-sm-65 {
    margin-left: auto;
    margin-right: 65%;
  }

  .offset-sm-70, .flex-offset-sm-70 {
    margin-left: 70%;
  }

  [dir=rtl] .offset-sm-70, [dir=rtl] .flex-offset-sm-70 {
    margin-left: auto;
    margin-right: 70%;
  }

  .offset-sm-75, .flex-offset-sm-75 {
    margin-left: 75%;
  }

  [dir=rtl] .offset-sm-75, [dir=rtl] .flex-offset-sm-75 {
    margin-left: auto;
    margin-right: 75%;
  }

  .offset-sm-80, .flex-offset-sm-80 {
    margin-left: 80%;
  }

  [dir=rtl] .offset-sm-80, [dir=rtl] .flex-offset-sm-80 {
    margin-left: auto;
    margin-right: 80%;
  }

  .offset-sm-85, .flex-offset-sm-85 {
    margin-left: 85%;
  }

  [dir=rtl] .offset-sm-85, [dir=rtl] .flex-offset-sm-85 {
    margin-left: auto;
    margin-right: 85%;
  }

  .offset-sm-90, .flex-offset-sm-90 {
    margin-left: 90%;
  }

  [dir=rtl] .offset-sm-90, [dir=rtl] .flex-offset-sm-90 {
    margin-left: auto;
    margin-right: 90%;
  }

  .offset-sm-95, .flex-offset-sm-95 {
    margin-left: 95%;
  }

  [dir=rtl] .offset-sm-95, [dir=rtl] .flex-offset-sm-95 {
    margin-left: auto;
    margin-right: 95%;
  }

  .offset-sm-33, .flex-offset-sm-33 {
    margin-left: calc(100% / 3);
  }

  .offset-sm-66, .flex-offset-sm-66 {
    margin-left: calc(200% / 3);
  }

  [dir=rtl] .offset-sm-66, [dir=rtl] .flex-offset-sm-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }

  .layout-align-sm,
  .layout-align-sm-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }

  .layout-align-sm-start,
  .layout-align-sm-start-start,
  .layout-align-sm-start-center,
  .layout-align-sm-start-end,
  .layout-align-sm-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-sm-center,
  .layout-align-sm-center-start,
  .layout-align-sm-center-center,
  .layout-align-sm-center-end,
  .layout-align-sm-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-sm-end,
  .layout-align-sm-end-start,
  .layout-align-sm-end-center,
  .layout-align-sm-end-end,
  .layout-align-sm-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-sm-space-around,
  .layout-align-sm-space-around-center,
  .layout-align-sm-space-around-start,
  .layout-align-sm-space-around-end,
  .layout-align-sm-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-sm-space-between,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-sm-start-start,
  .layout-align-sm-center-start,
  .layout-align-sm-end-start,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-around-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-sm-start-center,
  .layout-align-sm-center-center,
  .layout-align-sm-end-center,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-around-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-sm-start-center > *,
  .layout-align-sm-center-center > *,
  .layout-align-sm-end-center > *,
  .layout-align-sm-space-between-center > *,
  .layout-align-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-sm-start-end,
  .layout-align-sm-center-end,
  .layout-align-sm-end-end,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-around-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-sm-start-stretch,
  .layout-align-sm-center-stretch,
  .layout-align-sm-end-stretch,
  .layout-align-sm-space-between-stretch,
  .layout-align-sm-space-around-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-sm {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }

  .flex-sm-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-sm-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-sm-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-sm-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-sm-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-sm-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-column > .flex-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-sm-column > .flex-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .flex-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .flex-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .flex-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .flex-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .flex-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .flex-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .flex-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .flex-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .flex-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .flex-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .flex-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .flex-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .flex-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .flex-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .flex-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .flex-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .flex-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .flex-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .flex-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .flex-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-33, .layout-sm-row > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-66, .layout-sm-row > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex {
    min-width: 0;
  }

  .layout-sm-column > .flex-sm-33, .layout-sm-column > .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex-sm-66, .layout-sm-column > .flex-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-sm-column > .flex {
    min-height: 0;
  }

  .layout-sm, .layout-sm-column, .layout-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }

  .layout-sm-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .layout-sm-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 960px) {
  .flex-order-gt-sm--20 {
    -webkit-box-ordinal-group: -19;
    -webkit-order: -20;
    order: -20;
  }

  .flex-order-gt-sm--19 {
    -webkit-box-ordinal-group: -18;
    -webkit-order: -19;
    order: -19;
  }

  .flex-order-gt-sm--18 {
    -webkit-box-ordinal-group: -17;
    -webkit-order: -18;
    order: -18;
  }

  .flex-order-gt-sm--17 {
    -webkit-box-ordinal-group: -16;
    -webkit-order: -17;
    order: -17;
  }

  .flex-order-gt-sm--16 {
    -webkit-box-ordinal-group: -15;
    -webkit-order: -16;
    order: -16;
  }

  .flex-order-gt-sm--15 {
    -webkit-box-ordinal-group: -14;
    -webkit-order: -15;
    order: -15;
  }

  .flex-order-gt-sm--14 {
    -webkit-box-ordinal-group: -13;
    -webkit-order: -14;
    order: -14;
  }

  .flex-order-gt-sm--13 {
    -webkit-box-ordinal-group: -12;
    -webkit-order: -13;
    order: -13;
  }

  .flex-order-gt-sm--12 {
    -webkit-box-ordinal-group: -11;
    -webkit-order: -12;
    order: -12;
  }

  .flex-order-gt-sm--11 {
    -webkit-box-ordinal-group: -10;
    -webkit-order: -11;
    order: -11;
  }

  .flex-order-gt-sm--10 {
    -webkit-box-ordinal-group: -9;
    -webkit-order: -10;
    order: -10;
  }

  .flex-order-gt-sm--9 {
    -webkit-box-ordinal-group: -8;
    -webkit-order: -9;
    order: -9;
  }

  .flex-order-gt-sm--8 {
    -webkit-box-ordinal-group: -7;
    -webkit-order: -8;
    order: -8;
  }

  .flex-order-gt-sm--7 {
    -webkit-box-ordinal-group: -6;
    -webkit-order: -7;
    order: -7;
  }

  .flex-order-gt-sm--6 {
    -webkit-box-ordinal-group: -5;
    -webkit-order: -6;
    order: -6;
  }

  .flex-order-gt-sm--5 {
    -webkit-box-ordinal-group: -4;
    -webkit-order: -5;
    order: -5;
  }

  .flex-order-gt-sm--4 {
    -webkit-box-ordinal-group: -3;
    -webkit-order: -4;
    order: -4;
  }

  .flex-order-gt-sm--3 {
    -webkit-box-ordinal-group: -2;
    -webkit-order: -3;
    order: -3;
  }

  .flex-order-gt-sm--2 {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -2;
    order: -2;
  }

  .flex-order-gt-sm--1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    order: -1;
  }

  .flex-order-gt-sm-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
  }

  .flex-order-gt-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
  }

  .flex-order-gt-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }

  .flex-order-gt-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
  }

  .flex-order-gt-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    order: 4;
  }

  .flex-order-gt-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    order: 5;
  }

  .flex-order-gt-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    order: 6;
  }

  .flex-order-gt-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    order: 7;
  }

  .flex-order-gt-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    order: 8;
  }

  .flex-order-gt-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    order: 9;
  }

  .flex-order-gt-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    order: 10;
  }

  .flex-order-gt-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    order: 11;
  }

  .flex-order-gt-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    order: 12;
  }

  .flex-order-gt-sm-13 {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    order: 13;
  }

  .flex-order-gt-sm-14 {
    -webkit-box-ordinal-group: 15;
    -webkit-order: 14;
    order: 14;
  }

  .flex-order-gt-sm-15 {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    order: 15;
  }

  .flex-order-gt-sm-16 {
    -webkit-box-ordinal-group: 17;
    -webkit-order: 16;
    order: 16;
  }

  .flex-order-gt-sm-17 {
    -webkit-box-ordinal-group: 18;
    -webkit-order: 17;
    order: 17;
  }

  .flex-order-gt-sm-18 {
    -webkit-box-ordinal-group: 19;
    -webkit-order: 18;
    order: 18;
  }

  .flex-order-gt-sm-19 {
    -webkit-box-ordinal-group: 20;
    -webkit-order: 19;
    order: 19;
  }

  .flex-order-gt-sm-20 {
    -webkit-box-ordinal-group: 21;
    -webkit-order: 20;
    order: 20;
  }

  .offset-gt-sm-0, .flex-offset-gt-sm-0 {
    margin-left: 0;
  }

  [dir=rtl] .offset-gt-sm-0, [dir=rtl] .flex-offset-gt-sm-0 {
    margin-left: auto;
    margin-right: 0;
  }

  .offset-gt-sm-5, .flex-offset-gt-sm-5 {
    margin-left: 5%;
  }

  [dir=rtl] .offset-gt-sm-5, [dir=rtl] .flex-offset-gt-sm-5 {
    margin-left: auto;
    margin-right: 5%;
  }

  .offset-gt-sm-10, .flex-offset-gt-sm-10 {
    margin-left: 10%;
  }

  [dir=rtl] .offset-gt-sm-10, [dir=rtl] .flex-offset-gt-sm-10 {
    margin-left: auto;
    margin-right: 10%;
  }

  .offset-gt-sm-15, .flex-offset-gt-sm-15 {
    margin-left: 15%;
  }

  [dir=rtl] .offset-gt-sm-15, [dir=rtl] .flex-offset-gt-sm-15 {
    margin-left: auto;
    margin-right: 15%;
  }

  .offset-gt-sm-20, .flex-offset-gt-sm-20 {
    margin-left: 20%;
  }

  [dir=rtl] .offset-gt-sm-20, [dir=rtl] .flex-offset-gt-sm-20 {
    margin-left: auto;
    margin-right: 20%;
  }

  .offset-gt-sm-25, .flex-offset-gt-sm-25 {
    margin-left: 25%;
  }

  [dir=rtl] .offset-gt-sm-25, [dir=rtl] .flex-offset-gt-sm-25 {
    margin-left: auto;
    margin-right: 25%;
  }

  .offset-gt-sm-30, .flex-offset-gt-sm-30 {
    margin-left: 30%;
  }

  [dir=rtl] .offset-gt-sm-30, [dir=rtl] .flex-offset-gt-sm-30 {
    margin-left: auto;
    margin-right: 30%;
  }

  .offset-gt-sm-35, .flex-offset-gt-sm-35 {
    margin-left: 35%;
  }

  [dir=rtl] .offset-gt-sm-35, [dir=rtl] .flex-offset-gt-sm-35 {
    margin-left: auto;
    margin-right: 35%;
  }

  .offset-gt-sm-40, .flex-offset-gt-sm-40 {
    margin-left: 40%;
  }

  [dir=rtl] .offset-gt-sm-40, [dir=rtl] .flex-offset-gt-sm-40 {
    margin-left: auto;
    margin-right: 40%;
  }

  .offset-gt-sm-45, .flex-offset-gt-sm-45 {
    margin-left: 45%;
  }

  [dir=rtl] .offset-gt-sm-45, [dir=rtl] .flex-offset-gt-sm-45 {
    margin-left: auto;
    margin-right: 45%;
  }

  .offset-gt-sm-50, .flex-offset-gt-sm-50 {
    margin-left: 50%;
  }

  [dir=rtl] .offset-gt-sm-50, [dir=rtl] .flex-offset-gt-sm-50 {
    margin-left: auto;
    margin-right: 50%;
  }

  .offset-gt-sm-55, .flex-offset-gt-sm-55 {
    margin-left: 55%;
  }

  [dir=rtl] .offset-gt-sm-55, [dir=rtl] .flex-offset-gt-sm-55 {
    margin-left: auto;
    margin-right: 55%;
  }

  .offset-gt-sm-60, .flex-offset-gt-sm-60 {
    margin-left: 60%;
  }

  [dir=rtl] .offset-gt-sm-60, [dir=rtl] .flex-offset-gt-sm-60 {
    margin-left: auto;
    margin-right: 60%;
  }

  .offset-gt-sm-65, .flex-offset-gt-sm-65 {
    margin-left: 65%;
  }

  [dir=rtl] .offset-gt-sm-65, [dir=rtl] .flex-offset-gt-sm-65 {
    margin-left: auto;
    margin-right: 65%;
  }

  .offset-gt-sm-70, .flex-offset-gt-sm-70 {
    margin-left: 70%;
  }

  [dir=rtl] .offset-gt-sm-70, [dir=rtl] .flex-offset-gt-sm-70 {
    margin-left: auto;
    margin-right: 70%;
  }

  .offset-gt-sm-75, .flex-offset-gt-sm-75 {
    margin-left: 75%;
  }

  [dir=rtl] .offset-gt-sm-75, [dir=rtl] .flex-offset-gt-sm-75 {
    margin-left: auto;
    margin-right: 75%;
  }

  .offset-gt-sm-80, .flex-offset-gt-sm-80 {
    margin-left: 80%;
  }

  [dir=rtl] .offset-gt-sm-80, [dir=rtl] .flex-offset-gt-sm-80 {
    margin-left: auto;
    margin-right: 80%;
  }

  .offset-gt-sm-85, .flex-offset-gt-sm-85 {
    margin-left: 85%;
  }

  [dir=rtl] .offset-gt-sm-85, [dir=rtl] .flex-offset-gt-sm-85 {
    margin-left: auto;
    margin-right: 85%;
  }

  .offset-gt-sm-90, .flex-offset-gt-sm-90 {
    margin-left: 90%;
  }

  [dir=rtl] .offset-gt-sm-90, [dir=rtl] .flex-offset-gt-sm-90 {
    margin-left: auto;
    margin-right: 90%;
  }

  .offset-gt-sm-95, .flex-offset-gt-sm-95 {
    margin-left: 95%;
  }

  [dir=rtl] .offset-gt-sm-95, [dir=rtl] .flex-offset-gt-sm-95 {
    margin-left: auto;
    margin-right: 95%;
  }

  .offset-gt-sm-33, .flex-offset-gt-sm-33 {
    margin-left: calc(100% / 3);
  }

  .offset-gt-sm-66, .flex-offset-gt-sm-66 {
    margin-left: calc(200% / 3);
  }

  [dir=rtl] .offset-gt-sm-66, [dir=rtl] .flex-offset-gt-sm-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }

  .layout-align-gt-sm,
  .layout-align-gt-sm-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }

  .layout-align-gt-sm-start,
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-gt-sm-center,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-gt-sm-end,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-gt-sm-space-around,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-gt-sm-space-between,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-around-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-around-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-gt-sm-start-center > *,
  .layout-align-gt-sm-center-center > *,
  .layout-align-gt-sm-end-center > *,
  .layout-align-gt-sm-space-between-center > *,
  .layout-align-gt-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-around-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-gt-sm-start-stretch,
  .layout-align-gt-sm-center-stretch,
  .layout-align-gt-sm-end-stretch,
  .layout-align-gt-sm-space-between-stretch,
  .layout-align-gt-sm-space-around-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-gt-sm {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }

  .flex-gt-sm-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-column > .flex-gt-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-gt-sm-column > .flex-gt-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .flex-gt-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .flex-gt-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .flex-gt-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .flex-gt-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .flex-gt-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .flex-gt-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .flex-gt-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .flex-gt-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .flex-gt-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .flex-gt-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .flex-gt-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .flex-gt-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .flex-gt-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .flex-gt-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .flex-gt-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .flex-gt-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .flex-gt-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .flex-gt-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .flex-gt-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .flex-gt-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-33, .layout-gt-sm-row > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-66, .layout-gt-sm-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex {
    min-width: 0;
  }

  .layout-gt-sm-column > .flex-gt-sm-33, .layout-gt-sm-column > .flex-gt-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex-gt-sm-66, .layout-gt-sm-column > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-sm-column > .flex {
    min-height: 0;
  }

  .layout-gt-sm, .layout-gt-sm-column, .layout-gt-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }

  .layout-gt-sm-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .layout-gt-sm-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
    display: none !important;
  }

  .hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none !important;
  }

  .flex-order-md--20 {
    -webkit-box-ordinal-group: -19;
    -webkit-order: -20;
    order: -20;
  }

  .flex-order-md--19 {
    -webkit-box-ordinal-group: -18;
    -webkit-order: -19;
    order: -19;
  }

  .flex-order-md--18 {
    -webkit-box-ordinal-group: -17;
    -webkit-order: -18;
    order: -18;
  }

  .flex-order-md--17 {
    -webkit-box-ordinal-group: -16;
    -webkit-order: -17;
    order: -17;
  }

  .flex-order-md--16 {
    -webkit-box-ordinal-group: -15;
    -webkit-order: -16;
    order: -16;
  }

  .flex-order-md--15 {
    -webkit-box-ordinal-group: -14;
    -webkit-order: -15;
    order: -15;
  }

  .flex-order-md--14 {
    -webkit-box-ordinal-group: -13;
    -webkit-order: -14;
    order: -14;
  }

  .flex-order-md--13 {
    -webkit-box-ordinal-group: -12;
    -webkit-order: -13;
    order: -13;
  }

  .flex-order-md--12 {
    -webkit-box-ordinal-group: -11;
    -webkit-order: -12;
    order: -12;
  }

  .flex-order-md--11 {
    -webkit-box-ordinal-group: -10;
    -webkit-order: -11;
    order: -11;
  }

  .flex-order-md--10 {
    -webkit-box-ordinal-group: -9;
    -webkit-order: -10;
    order: -10;
  }

  .flex-order-md--9 {
    -webkit-box-ordinal-group: -8;
    -webkit-order: -9;
    order: -9;
  }

  .flex-order-md--8 {
    -webkit-box-ordinal-group: -7;
    -webkit-order: -8;
    order: -8;
  }

  .flex-order-md--7 {
    -webkit-box-ordinal-group: -6;
    -webkit-order: -7;
    order: -7;
  }

  .flex-order-md--6 {
    -webkit-box-ordinal-group: -5;
    -webkit-order: -6;
    order: -6;
  }

  .flex-order-md--5 {
    -webkit-box-ordinal-group: -4;
    -webkit-order: -5;
    order: -5;
  }

  .flex-order-md--4 {
    -webkit-box-ordinal-group: -3;
    -webkit-order: -4;
    order: -4;
  }

  .flex-order-md--3 {
    -webkit-box-ordinal-group: -2;
    -webkit-order: -3;
    order: -3;
  }

  .flex-order-md--2 {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -2;
    order: -2;
  }

  .flex-order-md--1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    order: -1;
  }

  .flex-order-md-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
  }

  .flex-order-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
  }

  .flex-order-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }

  .flex-order-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
  }

  .flex-order-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    order: 4;
  }

  .flex-order-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    order: 5;
  }

  .flex-order-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    order: 6;
  }

  .flex-order-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    order: 7;
  }

  .flex-order-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    order: 8;
  }

  .flex-order-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    order: 9;
  }

  .flex-order-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    order: 10;
  }

  .flex-order-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    order: 11;
  }

  .flex-order-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    order: 12;
  }

  .flex-order-md-13 {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    order: 13;
  }

  .flex-order-md-14 {
    -webkit-box-ordinal-group: 15;
    -webkit-order: 14;
    order: 14;
  }

  .flex-order-md-15 {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    order: 15;
  }

  .flex-order-md-16 {
    -webkit-box-ordinal-group: 17;
    -webkit-order: 16;
    order: 16;
  }

  .flex-order-md-17 {
    -webkit-box-ordinal-group: 18;
    -webkit-order: 17;
    order: 17;
  }

  .flex-order-md-18 {
    -webkit-box-ordinal-group: 19;
    -webkit-order: 18;
    order: 18;
  }

  .flex-order-md-19 {
    -webkit-box-ordinal-group: 20;
    -webkit-order: 19;
    order: 19;
  }

  .flex-order-md-20 {
    -webkit-box-ordinal-group: 21;
    -webkit-order: 20;
    order: 20;
  }

  .offset-md-0, .flex-offset-md-0 {
    margin-left: 0;
  }

  [dir=rtl] .offset-md-0, [dir=rtl] .flex-offset-md-0 {
    margin-left: auto;
    margin-right: 0;
  }

  .offset-md-5, .flex-offset-md-5 {
    margin-left: 5%;
  }

  [dir=rtl] .offset-md-5, [dir=rtl] .flex-offset-md-5 {
    margin-left: auto;
    margin-right: 5%;
  }

  .offset-md-10, .flex-offset-md-10 {
    margin-left: 10%;
  }

  [dir=rtl] .offset-md-10, [dir=rtl] .flex-offset-md-10 {
    margin-left: auto;
    margin-right: 10%;
  }

  .offset-md-15, .flex-offset-md-15 {
    margin-left: 15%;
  }

  [dir=rtl] .offset-md-15, [dir=rtl] .flex-offset-md-15 {
    margin-left: auto;
    margin-right: 15%;
  }

  .offset-md-20, .flex-offset-md-20 {
    margin-left: 20%;
  }

  [dir=rtl] .offset-md-20, [dir=rtl] .flex-offset-md-20 {
    margin-left: auto;
    margin-right: 20%;
  }

  .offset-md-25, .flex-offset-md-25 {
    margin-left: 25%;
  }

  [dir=rtl] .offset-md-25, [dir=rtl] .flex-offset-md-25 {
    margin-left: auto;
    margin-right: 25%;
  }

  .offset-md-30, .flex-offset-md-30 {
    margin-left: 30%;
  }

  [dir=rtl] .offset-md-30, [dir=rtl] .flex-offset-md-30 {
    margin-left: auto;
    margin-right: 30%;
  }

  .offset-md-35, .flex-offset-md-35 {
    margin-left: 35%;
  }

  [dir=rtl] .offset-md-35, [dir=rtl] .flex-offset-md-35 {
    margin-left: auto;
    margin-right: 35%;
  }

  .offset-md-40, .flex-offset-md-40 {
    margin-left: 40%;
  }

  [dir=rtl] .offset-md-40, [dir=rtl] .flex-offset-md-40 {
    margin-left: auto;
    margin-right: 40%;
  }

  .offset-md-45, .flex-offset-md-45 {
    margin-left: 45%;
  }

  [dir=rtl] .offset-md-45, [dir=rtl] .flex-offset-md-45 {
    margin-left: auto;
    margin-right: 45%;
  }

  .offset-md-50, .flex-offset-md-50 {
    margin-left: 50%;
  }

  [dir=rtl] .offset-md-50, [dir=rtl] .flex-offset-md-50 {
    margin-left: auto;
    margin-right: 50%;
  }

  .offset-md-55, .flex-offset-md-55 {
    margin-left: 55%;
  }

  [dir=rtl] .offset-md-55, [dir=rtl] .flex-offset-md-55 {
    margin-left: auto;
    margin-right: 55%;
  }

  .offset-md-60, .flex-offset-md-60 {
    margin-left: 60%;
  }

  [dir=rtl] .offset-md-60, [dir=rtl] .flex-offset-md-60 {
    margin-left: auto;
    margin-right: 60%;
  }

  .offset-md-65, .flex-offset-md-65 {
    margin-left: 65%;
  }

  [dir=rtl] .offset-md-65, [dir=rtl] .flex-offset-md-65 {
    margin-left: auto;
    margin-right: 65%;
  }

  .offset-md-70, .flex-offset-md-70 {
    margin-left: 70%;
  }

  [dir=rtl] .offset-md-70, [dir=rtl] .flex-offset-md-70 {
    margin-left: auto;
    margin-right: 70%;
  }

  .offset-md-75, .flex-offset-md-75 {
    margin-left: 75%;
  }

  [dir=rtl] .offset-md-75, [dir=rtl] .flex-offset-md-75 {
    margin-left: auto;
    margin-right: 75%;
  }

  .offset-md-80, .flex-offset-md-80 {
    margin-left: 80%;
  }

  [dir=rtl] .offset-md-80, [dir=rtl] .flex-offset-md-80 {
    margin-left: auto;
    margin-right: 80%;
  }

  .offset-md-85, .flex-offset-md-85 {
    margin-left: 85%;
  }

  [dir=rtl] .offset-md-85, [dir=rtl] .flex-offset-md-85 {
    margin-left: auto;
    margin-right: 85%;
  }

  .offset-md-90, .flex-offset-md-90 {
    margin-left: 90%;
  }

  [dir=rtl] .offset-md-90, [dir=rtl] .flex-offset-md-90 {
    margin-left: auto;
    margin-right: 90%;
  }

  .offset-md-95, .flex-offset-md-95 {
    margin-left: 95%;
  }

  [dir=rtl] .offset-md-95, [dir=rtl] .flex-offset-md-95 {
    margin-left: auto;
    margin-right: 95%;
  }

  .offset-md-33, .flex-offset-md-33 {
    margin-left: calc(100% / 3);
  }

  .offset-md-66, .flex-offset-md-66 {
    margin-left: calc(200% / 3);
  }

  [dir=rtl] .offset-md-66, [dir=rtl] .flex-offset-md-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }

  .layout-align-md,
  .layout-align-md-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }

  .layout-align-md-start,
  .layout-align-md-start-start,
  .layout-align-md-start-center,
  .layout-align-md-start-end,
  .layout-align-md-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-md-center,
  .layout-align-md-center-start,
  .layout-align-md-center-center,
  .layout-align-md-center-end,
  .layout-align-md-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-md-end,
  .layout-align-md-end-start,
  .layout-align-md-end-center,
  .layout-align-md-end-end,
  .layout-align-md-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-md-space-around,
  .layout-align-md-space-around-center,
  .layout-align-md-space-around-start,
  .layout-align-md-space-around-end,
  .layout-align-md-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-md-space-between,
  .layout-align-md-space-between-center,
  .layout-align-md-space-between-start,
  .layout-align-md-space-between-end,
  .layout-align-md-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-md-start-start,
  .layout-align-md-center-start,
  .layout-align-md-end-start,
  .layout-align-md-space-between-start,
  .layout-align-md-space-around-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-md-start-center,
  .layout-align-md-center-center,
  .layout-align-md-end-center,
  .layout-align-md-space-between-center,
  .layout-align-md-space-around-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-md-start-center > *,
  .layout-align-md-center-center > *,
  .layout-align-md-end-center > *,
  .layout-align-md-space-between-center > *,
  .layout-align-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-md-start-end,
  .layout-align-md-center-end,
  .layout-align-md-end-end,
  .layout-align-md-space-between-end,
  .layout-align-md-space-around-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-md-start-stretch,
  .layout-align-md-center-stretch,
  .layout-align-md-end-stretch,
  .layout-align-md-space-between-stretch,
  .layout-align-md-space-around-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-md {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }

  .flex-md-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-md-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-md-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-md-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-md-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-md-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-column > .flex-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-md-column > .flex-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .flex-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .flex-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .flex-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .flex-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .flex-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .flex-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .flex-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .flex-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .flex-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .flex-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .flex-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .flex-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .flex-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .flex-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .flex-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .flex-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .flex-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .flex-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .flex-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .flex-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-33, .layout-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-md-66, .layout-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-33, .layout-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-md-66, .layout-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-33, .layout-md-row > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex-md-66, .layout-md-row > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-md-row > .flex {
    min-width: 0;
  }

  .layout-md-column > .flex-md-33, .layout-md-column > .flex-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex-md-66, .layout-md-column > .flex-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-md-column > .flex {
    min-height: 0;
  }

  .layout-md, .layout-md-column, .layout-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }

  .layout-md-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .layout-md-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  .flex-order-gt-md--20 {
    -webkit-box-ordinal-group: -19;
    -webkit-order: -20;
    order: -20;
  }

  .flex-order-gt-md--19 {
    -webkit-box-ordinal-group: -18;
    -webkit-order: -19;
    order: -19;
  }

  .flex-order-gt-md--18 {
    -webkit-box-ordinal-group: -17;
    -webkit-order: -18;
    order: -18;
  }

  .flex-order-gt-md--17 {
    -webkit-box-ordinal-group: -16;
    -webkit-order: -17;
    order: -17;
  }

  .flex-order-gt-md--16 {
    -webkit-box-ordinal-group: -15;
    -webkit-order: -16;
    order: -16;
  }

  .flex-order-gt-md--15 {
    -webkit-box-ordinal-group: -14;
    -webkit-order: -15;
    order: -15;
  }

  .flex-order-gt-md--14 {
    -webkit-box-ordinal-group: -13;
    -webkit-order: -14;
    order: -14;
  }

  .flex-order-gt-md--13 {
    -webkit-box-ordinal-group: -12;
    -webkit-order: -13;
    order: -13;
  }

  .flex-order-gt-md--12 {
    -webkit-box-ordinal-group: -11;
    -webkit-order: -12;
    order: -12;
  }

  .flex-order-gt-md--11 {
    -webkit-box-ordinal-group: -10;
    -webkit-order: -11;
    order: -11;
  }

  .flex-order-gt-md--10 {
    -webkit-box-ordinal-group: -9;
    -webkit-order: -10;
    order: -10;
  }

  .flex-order-gt-md--9 {
    -webkit-box-ordinal-group: -8;
    -webkit-order: -9;
    order: -9;
  }

  .flex-order-gt-md--8 {
    -webkit-box-ordinal-group: -7;
    -webkit-order: -8;
    order: -8;
  }

  .flex-order-gt-md--7 {
    -webkit-box-ordinal-group: -6;
    -webkit-order: -7;
    order: -7;
  }

  .flex-order-gt-md--6 {
    -webkit-box-ordinal-group: -5;
    -webkit-order: -6;
    order: -6;
  }

  .flex-order-gt-md--5 {
    -webkit-box-ordinal-group: -4;
    -webkit-order: -5;
    order: -5;
  }

  .flex-order-gt-md--4 {
    -webkit-box-ordinal-group: -3;
    -webkit-order: -4;
    order: -4;
  }

  .flex-order-gt-md--3 {
    -webkit-box-ordinal-group: -2;
    -webkit-order: -3;
    order: -3;
  }

  .flex-order-gt-md--2 {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -2;
    order: -2;
  }

  .flex-order-gt-md--1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    order: -1;
  }

  .flex-order-gt-md-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
  }

  .flex-order-gt-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
  }

  .flex-order-gt-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }

  .flex-order-gt-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
  }

  .flex-order-gt-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    order: 4;
  }

  .flex-order-gt-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    order: 5;
  }

  .flex-order-gt-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    order: 6;
  }

  .flex-order-gt-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    order: 7;
  }

  .flex-order-gt-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    order: 8;
  }

  .flex-order-gt-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    order: 9;
  }

  .flex-order-gt-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    order: 10;
  }

  .flex-order-gt-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    order: 11;
  }

  .flex-order-gt-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    order: 12;
  }

  .flex-order-gt-md-13 {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    order: 13;
  }

  .flex-order-gt-md-14 {
    -webkit-box-ordinal-group: 15;
    -webkit-order: 14;
    order: 14;
  }

  .flex-order-gt-md-15 {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    order: 15;
  }

  .flex-order-gt-md-16 {
    -webkit-box-ordinal-group: 17;
    -webkit-order: 16;
    order: 16;
  }

  .flex-order-gt-md-17 {
    -webkit-box-ordinal-group: 18;
    -webkit-order: 17;
    order: 17;
  }

  .flex-order-gt-md-18 {
    -webkit-box-ordinal-group: 19;
    -webkit-order: 18;
    order: 18;
  }

  .flex-order-gt-md-19 {
    -webkit-box-ordinal-group: 20;
    -webkit-order: 19;
    order: 19;
  }

  .flex-order-gt-md-20 {
    -webkit-box-ordinal-group: 21;
    -webkit-order: 20;
    order: 20;
  }

  .offset-gt-md-0, .flex-offset-gt-md-0 {
    margin-left: 0;
  }

  [dir=rtl] .offset-gt-md-0, [dir=rtl] .flex-offset-gt-md-0 {
    margin-left: auto;
    margin-right: 0;
  }

  .offset-gt-md-5, .flex-offset-gt-md-5 {
    margin-left: 5%;
  }

  [dir=rtl] .offset-gt-md-5, [dir=rtl] .flex-offset-gt-md-5 {
    margin-left: auto;
    margin-right: 5%;
  }

  .offset-gt-md-10, .flex-offset-gt-md-10 {
    margin-left: 10%;
  }

  [dir=rtl] .offset-gt-md-10, [dir=rtl] .flex-offset-gt-md-10 {
    margin-left: auto;
    margin-right: 10%;
  }

  .offset-gt-md-15, .flex-offset-gt-md-15 {
    margin-left: 15%;
  }

  [dir=rtl] .offset-gt-md-15, [dir=rtl] .flex-offset-gt-md-15 {
    margin-left: auto;
    margin-right: 15%;
  }

  .offset-gt-md-20, .flex-offset-gt-md-20 {
    margin-left: 20%;
  }

  [dir=rtl] .offset-gt-md-20, [dir=rtl] .flex-offset-gt-md-20 {
    margin-left: auto;
    margin-right: 20%;
  }

  .offset-gt-md-25, .flex-offset-gt-md-25 {
    margin-left: 25%;
  }

  [dir=rtl] .offset-gt-md-25, [dir=rtl] .flex-offset-gt-md-25 {
    margin-left: auto;
    margin-right: 25%;
  }

  .offset-gt-md-30, .flex-offset-gt-md-30 {
    margin-left: 30%;
  }

  [dir=rtl] .offset-gt-md-30, [dir=rtl] .flex-offset-gt-md-30 {
    margin-left: auto;
    margin-right: 30%;
  }

  .offset-gt-md-35, .flex-offset-gt-md-35 {
    margin-left: 35%;
  }

  [dir=rtl] .offset-gt-md-35, [dir=rtl] .flex-offset-gt-md-35 {
    margin-left: auto;
    margin-right: 35%;
  }

  .offset-gt-md-40, .flex-offset-gt-md-40 {
    margin-left: 40%;
  }

  [dir=rtl] .offset-gt-md-40, [dir=rtl] .flex-offset-gt-md-40 {
    margin-left: auto;
    margin-right: 40%;
  }

  .offset-gt-md-45, .flex-offset-gt-md-45 {
    margin-left: 45%;
  }

  [dir=rtl] .offset-gt-md-45, [dir=rtl] .flex-offset-gt-md-45 {
    margin-left: auto;
    margin-right: 45%;
  }

  .offset-gt-md-50, .flex-offset-gt-md-50 {
    margin-left: 50%;
  }

  [dir=rtl] .offset-gt-md-50, [dir=rtl] .flex-offset-gt-md-50 {
    margin-left: auto;
    margin-right: 50%;
  }

  .offset-gt-md-55, .flex-offset-gt-md-55 {
    margin-left: 55%;
  }

  [dir=rtl] .offset-gt-md-55, [dir=rtl] .flex-offset-gt-md-55 {
    margin-left: auto;
    margin-right: 55%;
  }

  .offset-gt-md-60, .flex-offset-gt-md-60 {
    margin-left: 60%;
  }

  [dir=rtl] .offset-gt-md-60, [dir=rtl] .flex-offset-gt-md-60 {
    margin-left: auto;
    margin-right: 60%;
  }

  .offset-gt-md-65, .flex-offset-gt-md-65 {
    margin-left: 65%;
  }

  [dir=rtl] .offset-gt-md-65, [dir=rtl] .flex-offset-gt-md-65 {
    margin-left: auto;
    margin-right: 65%;
  }

  .offset-gt-md-70, .flex-offset-gt-md-70 {
    margin-left: 70%;
  }

  [dir=rtl] .offset-gt-md-70, [dir=rtl] .flex-offset-gt-md-70 {
    margin-left: auto;
    margin-right: 70%;
  }

  .offset-gt-md-75, .flex-offset-gt-md-75 {
    margin-left: 75%;
  }

  [dir=rtl] .offset-gt-md-75, [dir=rtl] .flex-offset-gt-md-75 {
    margin-left: auto;
    margin-right: 75%;
  }

  .offset-gt-md-80, .flex-offset-gt-md-80 {
    margin-left: 80%;
  }

  [dir=rtl] .offset-gt-md-80, [dir=rtl] .flex-offset-gt-md-80 {
    margin-left: auto;
    margin-right: 80%;
  }

  .offset-gt-md-85, .flex-offset-gt-md-85 {
    margin-left: 85%;
  }

  [dir=rtl] .offset-gt-md-85, [dir=rtl] .flex-offset-gt-md-85 {
    margin-left: auto;
    margin-right: 85%;
  }

  .offset-gt-md-90, .flex-offset-gt-md-90 {
    margin-left: 90%;
  }

  [dir=rtl] .offset-gt-md-90, [dir=rtl] .flex-offset-gt-md-90 {
    margin-left: auto;
    margin-right: 90%;
  }

  .offset-gt-md-95, .flex-offset-gt-md-95 {
    margin-left: 95%;
  }

  [dir=rtl] .offset-gt-md-95, [dir=rtl] .flex-offset-gt-md-95 {
    margin-left: auto;
    margin-right: 95%;
  }

  .offset-gt-md-33, .flex-offset-gt-md-33 {
    margin-left: calc(100% / 3);
  }

  .offset-gt-md-66, .flex-offset-gt-md-66 {
    margin-left: calc(200% / 3);
  }

  [dir=rtl] .offset-gt-md-66, [dir=rtl] .flex-offset-gt-md-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }

  .layout-align-gt-md,
  .layout-align-gt-md-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }

  .layout-align-gt-md-start,
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-gt-md-center,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-gt-md-end,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-gt-md-space-around,
  .layout-align-gt-md-space-around-center,
  .layout-align-gt-md-space-around-start,
  .layout-align-gt-md-space-around-end,
  .layout-align-gt-md-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-gt-md-space-between,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-gt-md-start-start,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-around-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-gt-md-start-center,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-around-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-gt-md-start-center > *,
  .layout-align-gt-md-center-center > *,
  .layout-align-gt-md-end-center > *,
  .layout-align-gt-md-space-between-center > *,
  .layout-align-gt-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-gt-md-start-end,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-around-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-gt-md-start-stretch,
  .layout-align-gt-md-center-stretch,
  .layout-align-gt-md-end-stretch,
  .layout-align-gt-md-space-between-stretch,
  .layout-align-gt-md-space-around-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-gt-md {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }

  .flex-gt-md-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-md-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-md-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-md-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-md-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-md-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-column > .flex-gt-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-gt-md-column > .flex-gt-md-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .flex-gt-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .flex-gt-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .flex-gt-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .flex-gt-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .flex-gt-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .flex-gt-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .flex-gt-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .flex-gt-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .flex-gt-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .flex-gt-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .flex-gt-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .flex-gt-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .flex-gt-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .flex-gt-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .flex-gt-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .flex-gt-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .flex-gt-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .flex-gt-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .flex-gt-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .flex-gt-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-33, .layout-gt-md-row > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex-gt-md-66, .layout-gt-md-row > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-md-row > .flex {
    min-width: 0;
  }

  .layout-gt-md-column > .flex-gt-md-33, .layout-gt-md-column > .flex-gt-md-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex-gt-md-66, .layout-gt-md-column > .flex-gt-md-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-md-column > .flex {
    min-height: 0;
  }

  .layout-gt-md, .layout-gt-md-column, .layout-gt-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }

  .layout-gt-md-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .layout-gt-md-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show) {
    display: none !important;
  }

  .hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none !important;
  }

  .flex-order-lg--20 {
    -webkit-box-ordinal-group: -19;
    -webkit-order: -20;
    order: -20;
  }

  .flex-order-lg--19 {
    -webkit-box-ordinal-group: -18;
    -webkit-order: -19;
    order: -19;
  }

  .flex-order-lg--18 {
    -webkit-box-ordinal-group: -17;
    -webkit-order: -18;
    order: -18;
  }

  .flex-order-lg--17 {
    -webkit-box-ordinal-group: -16;
    -webkit-order: -17;
    order: -17;
  }

  .flex-order-lg--16 {
    -webkit-box-ordinal-group: -15;
    -webkit-order: -16;
    order: -16;
  }

  .flex-order-lg--15 {
    -webkit-box-ordinal-group: -14;
    -webkit-order: -15;
    order: -15;
  }

  .flex-order-lg--14 {
    -webkit-box-ordinal-group: -13;
    -webkit-order: -14;
    order: -14;
  }

  .flex-order-lg--13 {
    -webkit-box-ordinal-group: -12;
    -webkit-order: -13;
    order: -13;
  }

  .flex-order-lg--12 {
    -webkit-box-ordinal-group: -11;
    -webkit-order: -12;
    order: -12;
  }

  .flex-order-lg--11 {
    -webkit-box-ordinal-group: -10;
    -webkit-order: -11;
    order: -11;
  }

  .flex-order-lg--10 {
    -webkit-box-ordinal-group: -9;
    -webkit-order: -10;
    order: -10;
  }

  .flex-order-lg--9 {
    -webkit-box-ordinal-group: -8;
    -webkit-order: -9;
    order: -9;
  }

  .flex-order-lg--8 {
    -webkit-box-ordinal-group: -7;
    -webkit-order: -8;
    order: -8;
  }

  .flex-order-lg--7 {
    -webkit-box-ordinal-group: -6;
    -webkit-order: -7;
    order: -7;
  }

  .flex-order-lg--6 {
    -webkit-box-ordinal-group: -5;
    -webkit-order: -6;
    order: -6;
  }

  .flex-order-lg--5 {
    -webkit-box-ordinal-group: -4;
    -webkit-order: -5;
    order: -5;
  }

  .flex-order-lg--4 {
    -webkit-box-ordinal-group: -3;
    -webkit-order: -4;
    order: -4;
  }

  .flex-order-lg--3 {
    -webkit-box-ordinal-group: -2;
    -webkit-order: -3;
    order: -3;
  }

  .flex-order-lg--2 {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -2;
    order: -2;
  }

  .flex-order-lg--1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    order: -1;
  }

  .flex-order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
  }

  .flex-order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
  }

  .flex-order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }

  .flex-order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
  }

  .flex-order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    order: 4;
  }

  .flex-order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    order: 5;
  }

  .flex-order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    order: 6;
  }

  .flex-order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    order: 7;
  }

  .flex-order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    order: 8;
  }

  .flex-order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    order: 9;
  }

  .flex-order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    order: 10;
  }

  .flex-order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    order: 11;
  }

  .flex-order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    order: 12;
  }

  .flex-order-lg-13 {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    order: 13;
  }

  .flex-order-lg-14 {
    -webkit-box-ordinal-group: 15;
    -webkit-order: 14;
    order: 14;
  }

  .flex-order-lg-15 {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    order: 15;
  }

  .flex-order-lg-16 {
    -webkit-box-ordinal-group: 17;
    -webkit-order: 16;
    order: 16;
  }

  .flex-order-lg-17 {
    -webkit-box-ordinal-group: 18;
    -webkit-order: 17;
    order: 17;
  }

  .flex-order-lg-18 {
    -webkit-box-ordinal-group: 19;
    -webkit-order: 18;
    order: 18;
  }

  .flex-order-lg-19 {
    -webkit-box-ordinal-group: 20;
    -webkit-order: 19;
    order: 19;
  }

  .flex-order-lg-20 {
    -webkit-box-ordinal-group: 21;
    -webkit-order: 20;
    order: 20;
  }

  .offset-lg-0, .flex-offset-lg-0 {
    margin-left: 0;
  }

  [dir=rtl] .offset-lg-0, [dir=rtl] .flex-offset-lg-0 {
    margin-left: auto;
    margin-right: 0;
  }

  .offset-lg-5, .flex-offset-lg-5 {
    margin-left: 5%;
  }

  [dir=rtl] .offset-lg-5, [dir=rtl] .flex-offset-lg-5 {
    margin-left: auto;
    margin-right: 5%;
  }

  .offset-lg-10, .flex-offset-lg-10 {
    margin-left: 10%;
  }

  [dir=rtl] .offset-lg-10, [dir=rtl] .flex-offset-lg-10 {
    margin-left: auto;
    margin-right: 10%;
  }

  .offset-lg-15, .flex-offset-lg-15 {
    margin-left: 15%;
  }

  [dir=rtl] .offset-lg-15, [dir=rtl] .flex-offset-lg-15 {
    margin-left: auto;
    margin-right: 15%;
  }

  .offset-lg-20, .flex-offset-lg-20 {
    margin-left: 20%;
  }

  [dir=rtl] .offset-lg-20, [dir=rtl] .flex-offset-lg-20 {
    margin-left: auto;
    margin-right: 20%;
  }

  .offset-lg-25, .flex-offset-lg-25 {
    margin-left: 25%;
  }

  [dir=rtl] .offset-lg-25, [dir=rtl] .flex-offset-lg-25 {
    margin-left: auto;
    margin-right: 25%;
  }

  .offset-lg-30, .flex-offset-lg-30 {
    margin-left: 30%;
  }

  [dir=rtl] .offset-lg-30, [dir=rtl] .flex-offset-lg-30 {
    margin-left: auto;
    margin-right: 30%;
  }

  .offset-lg-35, .flex-offset-lg-35 {
    margin-left: 35%;
  }

  [dir=rtl] .offset-lg-35, [dir=rtl] .flex-offset-lg-35 {
    margin-left: auto;
    margin-right: 35%;
  }

  .offset-lg-40, .flex-offset-lg-40 {
    margin-left: 40%;
  }

  [dir=rtl] .offset-lg-40, [dir=rtl] .flex-offset-lg-40 {
    margin-left: auto;
    margin-right: 40%;
  }

  .offset-lg-45, .flex-offset-lg-45 {
    margin-left: 45%;
  }

  [dir=rtl] .offset-lg-45, [dir=rtl] .flex-offset-lg-45 {
    margin-left: auto;
    margin-right: 45%;
  }

  .offset-lg-50, .flex-offset-lg-50 {
    margin-left: 50%;
  }

  [dir=rtl] .offset-lg-50, [dir=rtl] .flex-offset-lg-50 {
    margin-left: auto;
    margin-right: 50%;
  }

  .offset-lg-55, .flex-offset-lg-55 {
    margin-left: 55%;
  }

  [dir=rtl] .offset-lg-55, [dir=rtl] .flex-offset-lg-55 {
    margin-left: auto;
    margin-right: 55%;
  }

  .offset-lg-60, .flex-offset-lg-60 {
    margin-left: 60%;
  }

  [dir=rtl] .offset-lg-60, [dir=rtl] .flex-offset-lg-60 {
    margin-left: auto;
    margin-right: 60%;
  }

  .offset-lg-65, .flex-offset-lg-65 {
    margin-left: 65%;
  }

  [dir=rtl] .offset-lg-65, [dir=rtl] .flex-offset-lg-65 {
    margin-left: auto;
    margin-right: 65%;
  }

  .offset-lg-70, .flex-offset-lg-70 {
    margin-left: 70%;
  }

  [dir=rtl] .offset-lg-70, [dir=rtl] .flex-offset-lg-70 {
    margin-left: auto;
    margin-right: 70%;
  }

  .offset-lg-75, .flex-offset-lg-75 {
    margin-left: 75%;
  }

  [dir=rtl] .offset-lg-75, [dir=rtl] .flex-offset-lg-75 {
    margin-left: auto;
    margin-right: 75%;
  }

  .offset-lg-80, .flex-offset-lg-80 {
    margin-left: 80%;
  }

  [dir=rtl] .offset-lg-80, [dir=rtl] .flex-offset-lg-80 {
    margin-left: auto;
    margin-right: 80%;
  }

  .offset-lg-85, .flex-offset-lg-85 {
    margin-left: 85%;
  }

  [dir=rtl] .offset-lg-85, [dir=rtl] .flex-offset-lg-85 {
    margin-left: auto;
    margin-right: 85%;
  }

  .offset-lg-90, .flex-offset-lg-90 {
    margin-left: 90%;
  }

  [dir=rtl] .offset-lg-90, [dir=rtl] .flex-offset-lg-90 {
    margin-left: auto;
    margin-right: 90%;
  }

  .offset-lg-95, .flex-offset-lg-95 {
    margin-left: 95%;
  }

  [dir=rtl] .offset-lg-95, [dir=rtl] .flex-offset-lg-95 {
    margin-left: auto;
    margin-right: 95%;
  }

  .offset-lg-33, .flex-offset-lg-33 {
    margin-left: calc(100% / 3);
  }

  .offset-lg-66, .flex-offset-lg-66 {
    margin-left: calc(200% / 3);
  }

  [dir=rtl] .offset-lg-66, [dir=rtl] .flex-offset-lg-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }

  .layout-align-lg,
  .layout-align-lg-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }

  .layout-align-lg-start,
  .layout-align-lg-start-start,
  .layout-align-lg-start-center,
  .layout-align-lg-start-end,
  .layout-align-lg-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-lg-center,
  .layout-align-lg-center-start,
  .layout-align-lg-center-center,
  .layout-align-lg-center-end,
  .layout-align-lg-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-lg-end,
  .layout-align-lg-end-start,
  .layout-align-lg-end-center,
  .layout-align-lg-end-end,
  .layout-align-lg-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-lg-space-around,
  .layout-align-lg-space-around-center,
  .layout-align-lg-space-around-start,
  .layout-align-lg-space-around-end,
  .layout-align-lg-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-lg-space-between,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-lg-start-start,
  .layout-align-lg-center-start,
  .layout-align-lg-end-start,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-around-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-lg-start-center,
  .layout-align-lg-center-center,
  .layout-align-lg-end-center,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-around-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-lg-start-center > *,
  .layout-align-lg-center-center > *,
  .layout-align-lg-end-center > *,
  .layout-align-lg-space-between-center > *,
  .layout-align-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-lg-start-end,
  .layout-align-lg-center-end,
  .layout-align-lg-end-end,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-around-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-lg-start-stretch,
  .layout-align-lg-center-stretch,
  .layout-align-lg-end-stretch,
  .layout-align-lg-space-between-stretch,
  .layout-align-lg-space-around-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-lg {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }

  .flex-lg-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-lg-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-lg-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-lg-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-lg-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-lg-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-column > .flex-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-lg-column > .flex-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .flex-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .flex-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .flex-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .flex-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .flex-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .flex-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .flex-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .flex-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .flex-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .flex-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .flex-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .flex-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .flex-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .flex-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .flex-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .flex-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .flex-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .flex-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .flex-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .flex-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-33, .layout-lg-row > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex-lg-66, .layout-lg-row > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-lg-row > .flex {
    min-width: 0;
  }

  .layout-lg-column > .flex-lg-33, .layout-lg-column > .flex-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex-lg-66, .layout-lg-column > .flex-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-lg-column > .flex {
    min-height: 0;
  }

  .layout-lg, .layout-lg-column, .layout-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }

  .layout-lg-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .layout-lg-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1920px) {
  .flex-order-gt-lg--20 {
    -webkit-box-ordinal-group: -19;
    -webkit-order: -20;
    order: -20;
  }

  .flex-order-gt-lg--19 {
    -webkit-box-ordinal-group: -18;
    -webkit-order: -19;
    order: -19;
  }

  .flex-order-gt-lg--18 {
    -webkit-box-ordinal-group: -17;
    -webkit-order: -18;
    order: -18;
  }

  .flex-order-gt-lg--17 {
    -webkit-box-ordinal-group: -16;
    -webkit-order: -17;
    order: -17;
  }

  .flex-order-gt-lg--16 {
    -webkit-box-ordinal-group: -15;
    -webkit-order: -16;
    order: -16;
  }

  .flex-order-gt-lg--15 {
    -webkit-box-ordinal-group: -14;
    -webkit-order: -15;
    order: -15;
  }

  .flex-order-gt-lg--14 {
    -webkit-box-ordinal-group: -13;
    -webkit-order: -14;
    order: -14;
  }

  .flex-order-gt-lg--13 {
    -webkit-box-ordinal-group: -12;
    -webkit-order: -13;
    order: -13;
  }

  .flex-order-gt-lg--12 {
    -webkit-box-ordinal-group: -11;
    -webkit-order: -12;
    order: -12;
  }

  .flex-order-gt-lg--11 {
    -webkit-box-ordinal-group: -10;
    -webkit-order: -11;
    order: -11;
  }

  .flex-order-gt-lg--10 {
    -webkit-box-ordinal-group: -9;
    -webkit-order: -10;
    order: -10;
  }

  .flex-order-gt-lg--9 {
    -webkit-box-ordinal-group: -8;
    -webkit-order: -9;
    order: -9;
  }

  .flex-order-gt-lg--8 {
    -webkit-box-ordinal-group: -7;
    -webkit-order: -8;
    order: -8;
  }

  .flex-order-gt-lg--7 {
    -webkit-box-ordinal-group: -6;
    -webkit-order: -7;
    order: -7;
  }

  .flex-order-gt-lg--6 {
    -webkit-box-ordinal-group: -5;
    -webkit-order: -6;
    order: -6;
  }

  .flex-order-gt-lg--5 {
    -webkit-box-ordinal-group: -4;
    -webkit-order: -5;
    order: -5;
  }

  .flex-order-gt-lg--4 {
    -webkit-box-ordinal-group: -3;
    -webkit-order: -4;
    order: -4;
  }

  .flex-order-gt-lg--3 {
    -webkit-box-ordinal-group: -2;
    -webkit-order: -3;
    order: -3;
  }

  .flex-order-gt-lg--2 {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -2;
    order: -2;
  }

  .flex-order-gt-lg--1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    order: -1;
  }

  .flex-order-gt-lg-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
  }

  .flex-order-gt-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
  }

  .flex-order-gt-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }

  .flex-order-gt-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
  }

  .flex-order-gt-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    order: 4;
  }

  .flex-order-gt-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    order: 5;
  }

  .flex-order-gt-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    order: 6;
  }

  .flex-order-gt-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    order: 7;
  }

  .flex-order-gt-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    order: 8;
  }

  .flex-order-gt-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    order: 9;
  }

  .flex-order-gt-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    order: 10;
  }

  .flex-order-gt-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    order: 11;
  }

  .flex-order-gt-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    order: 12;
  }

  .flex-order-gt-lg-13 {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    order: 13;
  }

  .flex-order-gt-lg-14 {
    -webkit-box-ordinal-group: 15;
    -webkit-order: 14;
    order: 14;
  }

  .flex-order-gt-lg-15 {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    order: 15;
  }

  .flex-order-gt-lg-16 {
    -webkit-box-ordinal-group: 17;
    -webkit-order: 16;
    order: 16;
  }

  .flex-order-gt-lg-17 {
    -webkit-box-ordinal-group: 18;
    -webkit-order: 17;
    order: 17;
  }

  .flex-order-gt-lg-18 {
    -webkit-box-ordinal-group: 19;
    -webkit-order: 18;
    order: 18;
  }

  .flex-order-gt-lg-19 {
    -webkit-box-ordinal-group: 20;
    -webkit-order: 19;
    order: 19;
  }

  .flex-order-gt-lg-20 {
    -webkit-box-ordinal-group: 21;
    -webkit-order: 20;
    order: 20;
  }

  .offset-gt-lg-0, .flex-offset-gt-lg-0 {
    margin-left: 0;
  }

  [dir=rtl] .offset-gt-lg-0, [dir=rtl] .flex-offset-gt-lg-0 {
    margin-left: auto;
    margin-right: 0;
  }

  .offset-gt-lg-5, .flex-offset-gt-lg-5 {
    margin-left: 5%;
  }

  [dir=rtl] .offset-gt-lg-5, [dir=rtl] .flex-offset-gt-lg-5 {
    margin-left: auto;
    margin-right: 5%;
  }

  .offset-gt-lg-10, .flex-offset-gt-lg-10 {
    margin-left: 10%;
  }

  [dir=rtl] .offset-gt-lg-10, [dir=rtl] .flex-offset-gt-lg-10 {
    margin-left: auto;
    margin-right: 10%;
  }

  .offset-gt-lg-15, .flex-offset-gt-lg-15 {
    margin-left: 15%;
  }

  [dir=rtl] .offset-gt-lg-15, [dir=rtl] .flex-offset-gt-lg-15 {
    margin-left: auto;
    margin-right: 15%;
  }

  .offset-gt-lg-20, .flex-offset-gt-lg-20 {
    margin-left: 20%;
  }

  [dir=rtl] .offset-gt-lg-20, [dir=rtl] .flex-offset-gt-lg-20 {
    margin-left: auto;
    margin-right: 20%;
  }

  .offset-gt-lg-25, .flex-offset-gt-lg-25 {
    margin-left: 25%;
  }

  [dir=rtl] .offset-gt-lg-25, [dir=rtl] .flex-offset-gt-lg-25 {
    margin-left: auto;
    margin-right: 25%;
  }

  .offset-gt-lg-30, .flex-offset-gt-lg-30 {
    margin-left: 30%;
  }

  [dir=rtl] .offset-gt-lg-30, [dir=rtl] .flex-offset-gt-lg-30 {
    margin-left: auto;
    margin-right: 30%;
  }

  .offset-gt-lg-35, .flex-offset-gt-lg-35 {
    margin-left: 35%;
  }

  [dir=rtl] .offset-gt-lg-35, [dir=rtl] .flex-offset-gt-lg-35 {
    margin-left: auto;
    margin-right: 35%;
  }

  .offset-gt-lg-40, .flex-offset-gt-lg-40 {
    margin-left: 40%;
  }

  [dir=rtl] .offset-gt-lg-40, [dir=rtl] .flex-offset-gt-lg-40 {
    margin-left: auto;
    margin-right: 40%;
  }

  .offset-gt-lg-45, .flex-offset-gt-lg-45 {
    margin-left: 45%;
  }

  [dir=rtl] .offset-gt-lg-45, [dir=rtl] .flex-offset-gt-lg-45 {
    margin-left: auto;
    margin-right: 45%;
  }

  .offset-gt-lg-50, .flex-offset-gt-lg-50 {
    margin-left: 50%;
  }

  [dir=rtl] .offset-gt-lg-50, [dir=rtl] .flex-offset-gt-lg-50 {
    margin-left: auto;
    margin-right: 50%;
  }

  .offset-gt-lg-55, .flex-offset-gt-lg-55 {
    margin-left: 55%;
  }

  [dir=rtl] .offset-gt-lg-55, [dir=rtl] .flex-offset-gt-lg-55 {
    margin-left: auto;
    margin-right: 55%;
  }

  .offset-gt-lg-60, .flex-offset-gt-lg-60 {
    margin-left: 60%;
  }

  [dir=rtl] .offset-gt-lg-60, [dir=rtl] .flex-offset-gt-lg-60 {
    margin-left: auto;
    margin-right: 60%;
  }

  .offset-gt-lg-65, .flex-offset-gt-lg-65 {
    margin-left: 65%;
  }

  [dir=rtl] .offset-gt-lg-65, [dir=rtl] .flex-offset-gt-lg-65 {
    margin-left: auto;
    margin-right: 65%;
  }

  .offset-gt-lg-70, .flex-offset-gt-lg-70 {
    margin-left: 70%;
  }

  [dir=rtl] .offset-gt-lg-70, [dir=rtl] .flex-offset-gt-lg-70 {
    margin-left: auto;
    margin-right: 70%;
  }

  .offset-gt-lg-75, .flex-offset-gt-lg-75 {
    margin-left: 75%;
  }

  [dir=rtl] .offset-gt-lg-75, [dir=rtl] .flex-offset-gt-lg-75 {
    margin-left: auto;
    margin-right: 75%;
  }

  .offset-gt-lg-80, .flex-offset-gt-lg-80 {
    margin-left: 80%;
  }

  [dir=rtl] .offset-gt-lg-80, [dir=rtl] .flex-offset-gt-lg-80 {
    margin-left: auto;
    margin-right: 80%;
  }

  .offset-gt-lg-85, .flex-offset-gt-lg-85 {
    margin-left: 85%;
  }

  [dir=rtl] .offset-gt-lg-85, [dir=rtl] .flex-offset-gt-lg-85 {
    margin-left: auto;
    margin-right: 85%;
  }

  .offset-gt-lg-90, .flex-offset-gt-lg-90 {
    margin-left: 90%;
  }

  [dir=rtl] .offset-gt-lg-90, [dir=rtl] .flex-offset-gt-lg-90 {
    margin-left: auto;
    margin-right: 90%;
  }

  .offset-gt-lg-95, .flex-offset-gt-lg-95 {
    margin-left: 95%;
  }

  [dir=rtl] .offset-gt-lg-95, [dir=rtl] .flex-offset-gt-lg-95 {
    margin-left: auto;
    margin-right: 95%;
  }

  .offset-gt-lg-33, .flex-offset-gt-lg-33 {
    margin-left: calc(100% / 3);
  }

  .offset-gt-lg-66, .flex-offset-gt-lg-66 {
    margin-left: calc(200% / 3);
  }

  [dir=rtl] .offset-gt-lg-66, [dir=rtl] .flex-offset-gt-lg-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }

  .layout-align-gt-lg,
  .layout-align-gt-lg-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }

  .layout-align-gt-lg-start,
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-gt-lg-center,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-gt-lg-end,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-gt-lg-space-around,
  .layout-align-gt-lg-space-around-center,
  .layout-align-gt-lg-space-around-start,
  .layout-align-gt-lg-space-around-end,
  .layout-align-gt-lg-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-gt-lg-space-between,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-around-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-around-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-gt-lg-start-center > *,
  .layout-align-gt-lg-center-center > *,
  .layout-align-gt-lg-end-center > *,
  .layout-align-gt-lg-space-between-center > *,
  .layout-align-gt-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-around-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-gt-lg-start-stretch,
  .layout-align-gt-lg-center-stretch,
  .layout-align-gt-lg-end-stretch,
  .layout-align-gt-lg-space-between-stretch,
  .layout-align-gt-lg-space-around-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-gt-lg {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }

  .flex-gt-lg-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-lg-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-lg-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-lg-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-lg-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-lg-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-column > .flex-gt-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-gt-lg-column > .flex-gt-lg-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .flex-gt-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .flex-gt-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .flex-gt-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .flex-gt-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .flex-gt-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .flex-gt-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .flex-gt-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .flex-gt-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .flex-gt-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .flex-gt-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .flex-gt-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .flex-gt-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .flex-gt-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .flex-gt-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .flex-gt-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .flex-gt-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .flex-gt-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .flex-gt-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .flex-gt-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .flex-gt-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-33, .layout-gt-lg-row > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex-gt-lg-66, .layout-gt-lg-row > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-gt-lg-row > .flex {
    min-width: 0;
  }

  .layout-gt-lg-column > .flex-gt-lg-33, .layout-gt-lg-column > .flex-gt-lg-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex-gt-lg-66, .layout-gt-lg-column > .flex-gt-lg-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-gt-lg-column > .flex {
    min-height: 0;
  }

  .layout-gt-lg, .layout-gt-lg-column, .layout-gt-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }

  .layout-gt-lg-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .layout-gt-lg-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .flex-order-xl--20 {
    -webkit-box-ordinal-group: -19;
    -webkit-order: -20;
    order: -20;
  }

  .flex-order-xl--19 {
    -webkit-box-ordinal-group: -18;
    -webkit-order: -19;
    order: -19;
  }

  .flex-order-xl--18 {
    -webkit-box-ordinal-group: -17;
    -webkit-order: -18;
    order: -18;
  }

  .flex-order-xl--17 {
    -webkit-box-ordinal-group: -16;
    -webkit-order: -17;
    order: -17;
  }

  .flex-order-xl--16 {
    -webkit-box-ordinal-group: -15;
    -webkit-order: -16;
    order: -16;
  }

  .flex-order-xl--15 {
    -webkit-box-ordinal-group: -14;
    -webkit-order: -15;
    order: -15;
  }

  .flex-order-xl--14 {
    -webkit-box-ordinal-group: -13;
    -webkit-order: -14;
    order: -14;
  }

  .flex-order-xl--13 {
    -webkit-box-ordinal-group: -12;
    -webkit-order: -13;
    order: -13;
  }

  .flex-order-xl--12 {
    -webkit-box-ordinal-group: -11;
    -webkit-order: -12;
    order: -12;
  }

  .flex-order-xl--11 {
    -webkit-box-ordinal-group: -10;
    -webkit-order: -11;
    order: -11;
  }

  .flex-order-xl--10 {
    -webkit-box-ordinal-group: -9;
    -webkit-order: -10;
    order: -10;
  }

  .flex-order-xl--9 {
    -webkit-box-ordinal-group: -8;
    -webkit-order: -9;
    order: -9;
  }

  .flex-order-xl--8 {
    -webkit-box-ordinal-group: -7;
    -webkit-order: -8;
    order: -8;
  }

  .flex-order-xl--7 {
    -webkit-box-ordinal-group: -6;
    -webkit-order: -7;
    order: -7;
  }

  .flex-order-xl--6 {
    -webkit-box-ordinal-group: -5;
    -webkit-order: -6;
    order: -6;
  }

  .flex-order-xl--5 {
    -webkit-box-ordinal-group: -4;
    -webkit-order: -5;
    order: -5;
  }

  .flex-order-xl--4 {
    -webkit-box-ordinal-group: -3;
    -webkit-order: -4;
    order: -4;
  }

  .flex-order-xl--3 {
    -webkit-box-ordinal-group: -2;
    -webkit-order: -3;
    order: -3;
  }

  .flex-order-xl--2 {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -2;
    order: -2;
  }

  .flex-order-xl--1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    order: -1;
  }

  .flex-order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
  }

  .flex-order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
  }

  .flex-order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }

  .flex-order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
  }

  .flex-order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    order: 4;
  }

  .flex-order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    order: 5;
  }

  .flex-order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    order: 6;
  }

  .flex-order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    order: 7;
  }

  .flex-order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    order: 8;
  }

  .flex-order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    order: 9;
  }

  .flex-order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    order: 10;
  }

  .flex-order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    order: 11;
  }

  .flex-order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    order: 12;
  }

  .flex-order-xl-13 {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    order: 13;
  }

  .flex-order-xl-14 {
    -webkit-box-ordinal-group: 15;
    -webkit-order: 14;
    order: 14;
  }

  .flex-order-xl-15 {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    order: 15;
  }

  .flex-order-xl-16 {
    -webkit-box-ordinal-group: 17;
    -webkit-order: 16;
    order: 16;
  }

  .flex-order-xl-17 {
    -webkit-box-ordinal-group: 18;
    -webkit-order: 17;
    order: 17;
  }

  .flex-order-xl-18 {
    -webkit-box-ordinal-group: 19;
    -webkit-order: 18;
    order: 18;
  }

  .flex-order-xl-19 {
    -webkit-box-ordinal-group: 20;
    -webkit-order: 19;
    order: 19;
  }

  .flex-order-xl-20 {
    -webkit-box-ordinal-group: 21;
    -webkit-order: 20;
    order: 20;
  }

  .offset-xl-0, .flex-offset-xl-0 {
    margin-left: 0;
  }

  [dir=rtl] .offset-xl-0, [dir=rtl] .flex-offset-xl-0 {
    margin-left: auto;
    margin-right: 0;
  }

  .offset-xl-5, .flex-offset-xl-5 {
    margin-left: 5%;
  }

  [dir=rtl] .offset-xl-5, [dir=rtl] .flex-offset-xl-5 {
    margin-left: auto;
    margin-right: 5%;
  }

  .offset-xl-10, .flex-offset-xl-10 {
    margin-left: 10%;
  }

  [dir=rtl] .offset-xl-10, [dir=rtl] .flex-offset-xl-10 {
    margin-left: auto;
    margin-right: 10%;
  }

  .offset-xl-15, .flex-offset-xl-15 {
    margin-left: 15%;
  }

  [dir=rtl] .offset-xl-15, [dir=rtl] .flex-offset-xl-15 {
    margin-left: auto;
    margin-right: 15%;
  }

  .offset-xl-20, .flex-offset-xl-20 {
    margin-left: 20%;
  }

  [dir=rtl] .offset-xl-20, [dir=rtl] .flex-offset-xl-20 {
    margin-left: auto;
    margin-right: 20%;
  }

  .offset-xl-25, .flex-offset-xl-25 {
    margin-left: 25%;
  }

  [dir=rtl] .offset-xl-25, [dir=rtl] .flex-offset-xl-25 {
    margin-left: auto;
    margin-right: 25%;
  }

  .offset-xl-30, .flex-offset-xl-30 {
    margin-left: 30%;
  }

  [dir=rtl] .offset-xl-30, [dir=rtl] .flex-offset-xl-30 {
    margin-left: auto;
    margin-right: 30%;
  }

  .offset-xl-35, .flex-offset-xl-35 {
    margin-left: 35%;
  }

  [dir=rtl] .offset-xl-35, [dir=rtl] .flex-offset-xl-35 {
    margin-left: auto;
    margin-right: 35%;
  }

  .offset-xl-40, .flex-offset-xl-40 {
    margin-left: 40%;
  }

  [dir=rtl] .offset-xl-40, [dir=rtl] .flex-offset-xl-40 {
    margin-left: auto;
    margin-right: 40%;
  }

  .offset-xl-45, .flex-offset-xl-45 {
    margin-left: 45%;
  }

  [dir=rtl] .offset-xl-45, [dir=rtl] .flex-offset-xl-45 {
    margin-left: auto;
    margin-right: 45%;
  }

  .offset-xl-50, .flex-offset-xl-50 {
    margin-left: 50%;
  }

  [dir=rtl] .offset-xl-50, [dir=rtl] .flex-offset-xl-50 {
    margin-left: auto;
    margin-right: 50%;
  }

  .offset-xl-55, .flex-offset-xl-55 {
    margin-left: 55%;
  }

  [dir=rtl] .offset-xl-55, [dir=rtl] .flex-offset-xl-55 {
    margin-left: auto;
    margin-right: 55%;
  }

  .offset-xl-60, .flex-offset-xl-60 {
    margin-left: 60%;
  }

  [dir=rtl] .offset-xl-60, [dir=rtl] .flex-offset-xl-60 {
    margin-left: auto;
    margin-right: 60%;
  }

  .offset-xl-65, .flex-offset-xl-65 {
    margin-left: 65%;
  }

  [dir=rtl] .offset-xl-65, [dir=rtl] .flex-offset-xl-65 {
    margin-left: auto;
    margin-right: 65%;
  }

  .offset-xl-70, .flex-offset-xl-70 {
    margin-left: 70%;
  }

  [dir=rtl] .offset-xl-70, [dir=rtl] .flex-offset-xl-70 {
    margin-left: auto;
    margin-right: 70%;
  }

  .offset-xl-75, .flex-offset-xl-75 {
    margin-left: 75%;
  }

  [dir=rtl] .offset-xl-75, [dir=rtl] .flex-offset-xl-75 {
    margin-left: auto;
    margin-right: 75%;
  }

  .offset-xl-80, .flex-offset-xl-80 {
    margin-left: 80%;
  }

  [dir=rtl] .offset-xl-80, [dir=rtl] .flex-offset-xl-80 {
    margin-left: auto;
    margin-right: 80%;
  }

  .offset-xl-85, .flex-offset-xl-85 {
    margin-left: 85%;
  }

  [dir=rtl] .offset-xl-85, [dir=rtl] .flex-offset-xl-85 {
    margin-left: auto;
    margin-right: 85%;
  }

  .offset-xl-90, .flex-offset-xl-90 {
    margin-left: 90%;
  }

  [dir=rtl] .offset-xl-90, [dir=rtl] .flex-offset-xl-90 {
    margin-left: auto;
    margin-right: 90%;
  }

  .offset-xl-95, .flex-offset-xl-95 {
    margin-left: 95%;
  }

  [dir=rtl] .offset-xl-95, [dir=rtl] .flex-offset-xl-95 {
    margin-left: auto;
    margin-right: 95%;
  }

  .offset-xl-33, .flex-offset-xl-33 {
    margin-left: calc(100% / 3);
  }

  .offset-xl-66, .flex-offset-xl-66 {
    margin-left: calc(200% / 3);
  }

  [dir=rtl] .offset-xl-66, [dir=rtl] .flex-offset-xl-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }

  .layout-align-xl,
  .layout-align-xl-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }

  .layout-align-xl-start,
  .layout-align-xl-start-start,
  .layout-align-xl-start-center,
  .layout-align-xl-start-end,
  .layout-align-xl-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-xl-center,
  .layout-align-xl-center-start,
  .layout-align-xl-center-center,
  .layout-align-xl-center-end,
  .layout-align-xl-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-xl-end,
  .layout-align-xl-end-start,
  .layout-align-xl-end-center,
  .layout-align-xl-end-end,
  .layout-align-xl-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-xl-space-around,
  .layout-align-xl-space-around-center,
  .layout-align-xl-space-around-start,
  .layout-align-xl-space-around-end,
  .layout-align-xl-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-xl-space-between,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-xl-start-start,
  .layout-align-xl-center-start,
  .layout-align-xl-end-start,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-around-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-xl-start-center,
  .layout-align-xl-center-center,
  .layout-align-xl-end-center,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-around-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-xl-start-center > *,
  .layout-align-xl-center-center > *,
  .layout-align-xl-end-center > *,
  .layout-align-xl-space-between-center > *,
  .layout-align-xl-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-xl-start-end,
  .layout-align-xl-center-end,
  .layout-align-xl-end-end,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-around-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-xl-start-stretch,
  .layout-align-xl-center-stretch,
  .layout-align-xl-end-stretch,
  .layout-align-xl-space-between-stretch,
  .layout-align-xl-space-around-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-xl {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }

  .flex-xl-grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-xl-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-xl-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-xl-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-xl-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-xl-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-xl-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-column > .flex-xl-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }

  .layout-xl-column > .flex-xl-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .flex-xl-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }

  .flex-xl-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }

  .flex-xl-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }

  .flex-xl-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }

  .flex-xl-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }

  .flex-xl-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }

  .flex-xl-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }

  .flex-xl-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }

  .flex-xl-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }

  .flex-xl-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }

  .flex-xl-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }

  .flex-xl-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }

  .flex-xl-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }

  .flex-xl-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }

  .flex-xl-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }

  .flex-xl-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }

  .flex-xl-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-85 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }

  .flex-xl-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-90 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .flex-xl-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-95 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }

  .flex-xl-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-33, .layout-xl-row > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex-xl-66, .layout-xl-row > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-xl-row > .flex {
    min-width: 0;
  }

  .layout-xl-column > .flex-xl-33, .layout-xl-column > .flex-xl-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex-xl-66, .layout-xl-column > .flex-xl-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }

  .layout-xl-column > .flex {
    min-height: 0;
  }

  .layout-xl, .layout-xl-column, .layout-xl-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }

  .layout-xl-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .layout-xl-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-lg:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show) {
    display: none !important;
  }

  .hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none !important;
  }
}

@media print {
  .hide-print:not(.show-print):not(.show) {
    display: none !important;
  }
}

//Own
.flex-align-start {
  align-self: flex-start;
}

.flex-align-center {
  align-self: center;
}

.flex-align-end {
  align-self: flex-start;
}

.flex-align-stretch {
  align-self: stretch;
}

.flex-align-baseline {
  align-self: baseline;
}
