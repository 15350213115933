.--opacity-0 {
  opacity: 0.0 !important;
}

.--opacity-10 {
  opacity: 0.1 !important;
}

.--opacity-20 {
  opacity: 0.2 !important;
}

.--opacity-30 {
  opacity: 0.3 !important;
}

.--opacity-40 {
  opacity: 0.4 !important;
}

.--opacity-50 {
  opacity: 0.5 !important;
}

.--opacity-60 {
  opacity: 0.6 !important;
}

.--opacity-70 {
  opacity: 0.7 !important;
}

.--opacity-80 {
  opacity: 0.8 !important;
}

.--opacity-90 {
  opacity: 0.9 !important;
}

.--opacity-100 {
  opacity: 1.0 !important;
}
