@import "config";

@media (max-width: 600px) {
  .typo-display {
    font-size: 1.6rem!important;
    font-weight: 100!important;
    line-height: 30px!important;
    opacity: 0.7;
  }
}

.typo-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.typo-body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.typo-body-2 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.typo-subhead {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.typo-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.8;
}

.typo-headline {
  font-size: 24px;
  font-weight: 700;;
  line-height: 32px;
}

.typo-display {
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  opacity: 0.7;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.text-align {
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}

.text-bold {
  font-weight: 700;
}

.text-small {
  font-size: 12px;
}


