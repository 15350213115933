@import "../config";

.at-fieldset {
  display: grid;
  grid-column-gap: 4px;
  grid-row-gap: 16px;
  grid-auto-flow: row;
  grid-template-columns: repeat(6, minmax(0, 1fr));

  & > * {
    grid-column-end: span 6;
  }
}

.at-fieldset--compact {
  grid-auto-flow: row dense;
}

.at-fieldset--span-1 {
  grid-column-end: span 1!important;
}

.at-fieldset--span-2 {
  grid-column-end: span 2;
}

.at-fieldset--span-3 {
  grid-column-end: span 3!important;
}

.at-fieldset--span-4 {
  grid-column-end: span 4!important;
}

.at-fieldset--span-5 {
  grid-column-end: span 5;
}

.at-fieldset--span-6 {
  grid-column-end: span 6;
}

.at-fieldset--span-7 {
  grid-column-end: span 6;
}

.at-fieldset--span-8 {
  grid-column-end: span 6;
}

.at-fieldset--span-9 {
  grid-column-end: span 6;
}

.at-fieldset--span-10 {
  grid-column-end: span 6;
}

.at-fieldset--span-11 {
  grid-column-end: span 6;
}

.at-fieldset--span-12 {
  grid-column-end: span 6;
}


@media (min-width: 600px) {
  .at-fieldset {
    grid-template-columns: repeat(12, minmax(0, 1fr));

    & > * {
      grid-column-end: span 12;
    }
  }

  .at-fieldset--span-1 {
    grid-column-end: span 1;
  }

  .at-fieldset--span-2 {
    grid-column-end: span 2;
  }

  .at-fieldset--span-3 {
    grid-column-end: span 3;
  }

  .at-fieldset--span-4 {
    grid-column-end: span 4!important;
  }

  .at-fieldset--span-5 {
    grid-column-end: span 5;
  }

  .at-fieldset--span-6 {
    grid-column-end: span 6;
  }

  .at-fieldset--span-7 {
    grid-column-end: span 7;
  }

  .at-fieldset--span-8 {
    grid-column-end: span 8;
  }

  .at-fieldset--span-9 {
    grid-column-end: span 9;
  }

  .at-fieldset--span-10 {
    grid-column-end: span 10;
  }

  .at-fieldset--span-11 {
    grid-column-end: span 11;
  }

  .at-fieldset--span-12 {
    grid-column-end: span 12;
  }
}
