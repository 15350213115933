[layout] {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

[layout=column] {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

[layout=row] {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

[layout-padding] [layout-padding] > [flex] {
  padding: 8px;
}

[layout-margin],
[layout-margin] > [flex] {
  margin: 8px;
}

[layout-padding] + [layout-padding],
[layout-margin] + [layout-margin] {
  margin-top: -8px;
}

[layout-wrap] {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

[layout-fill] {
  margin: 0;
  min-height: 100%;
  width: 100%;
}

[flex] {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

[flex="0"] {
  -webkit-flex: 0 0 0%;
  -ms-flex: 0 0 0%;
  flex: 0 0 0%;
  max-width: 0%;
}

[flex="5"] {
  -webkit-flex: 0 0 5%;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  max-width: 5%;
}

[flex="10"] {
  -webkit-flex: 0 0 10%;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  max-width: 10%;
}

[flex="15"] {
  -webkit-flex: 0 0 15%;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  max-width: 15%;
}

[flex="20"] {
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

[flex="25"] {
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

[flex="30"] {
  -webkit-flex: 0 0 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}

[flex="35"] {
  -webkit-flex: 0 0 35%;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  max-width: 35%;
}

[flex="40"] {
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}

[flex="45"] {
  -webkit-flex: 0 0 45%;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
}

[flex="50"] {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

[flex="55"] {
  -webkit-flex: 0 0 55%;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%;
}

[flex="60"] {
  -webkit-flex: 0 0 60%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}

[flex="65"] {
  -webkit-flex: 0 0 65%;
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
  max-width: 65%;
}

[flex="70"] {
  -webkit-flex: 0 0 70%;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
}

[flex="75"] {
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

[flex="80"] {
  -webkit-flex: 0 0 80%;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  max-width: 80%;
}

[flex="85"] {
  -webkit-flex: 0 0 85%;
  -ms-flex: 0 0 85%;
  flex: 0 0 85%;
  max-width: 85%;
}

[flex="90"] {
  -webkit-flex: 0 0 90%;
  -ms-flex: 0 0 90%;
  flex: 0 0 90%;
  max-width: 90%;
}

[flex="95"] {
  -webkit-flex: 0 0 95%;
  -ms-flex: 0 0 95%;
  flex: 0 0 95%;
  max-width: 95%;
}

[flex="100"] {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

[flex="33"],
[flex="34"] {
  -webkit-flex: 0 0 33.33%;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

[flex="66"],
[flex="67"] {
  -webkit-flex: 0 0 66.66%;
  -ms-flex: 0 0 66.66%;
  flex: 0 0 66.66%;
  max-width: 66.66%;
}

[hide]:not([show]) {
  display: none !important;
}

@media (max-width: 600px) {
  [hide-sm]:not([show-sm]),
  [hide]:not([show-sm]) {
    display: none !important;
  }

  [layout-sm] {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  [layout-sm=column] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  [layout-sm=row] {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  [flex-sm] {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  [flex-sm="0"] {
    -webkit-flex: 0 0 0%;
    -ms-flex: 0 0 0%;
    flex: 0 0 0%;
    max-width: 0%;
  }

  [flex-sm="5"] {
    -webkit-flex: 0 0 5%;
    -ms-flex: 0 0 5%;
    flex: 0 0 5%;
    max-width: 5%;
  }

  [flex-sm="10"] {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  [flex-sm="15"] {
    -webkit-flex: 0 0 15%;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  [flex-sm="20"] {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  [flex-sm="25"] {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  [flex-sm="30"] {
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  [flex-sm="35"] {
    -webkit-flex: 0 0 35%;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }

  [flex-sm="40"] {
    -webkit-flex: 0 0 40%;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  [flex-sm="45"] {
    -webkit-flex: 0 0 45%;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }

  [flex-sm="50"] {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  [flex-sm="55"] {
    -webkit-flex: 0 0 55%;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
  }

  [flex-sm="60"] {
    -webkit-flex: 0 0 60%;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  [flex-sm="65"] {
    -webkit-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
  }

  [flex-sm="70"] {
    -webkit-flex: 0 0 70%;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }

  [flex-sm="75"] {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  [flex-sm="80"] {
    -webkit-flex: 0 0 80%;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }

  [flex-sm="85"] {
    -webkit-flex: 0 0 85%;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }

  [flex-sm="90"] {
    -webkit-flex: 0 0 90%;
    -ms-flex: 0 0 90%;
    flex: 0 0 90%;
    max-width: 90%;
  }

  [flex-sm="95"] {
    -webkit-flex: 0 0 95%;
    -ms-flex: 0 0 95%;
    flex: 0 0 95%;
    max-width: 95%;
  }

  [flex-sm="100"] {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  [flex-sm="33"],
  [flex-sm="34"] {
    -webkit-flex: 0 0 33.33%;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  [flex-sm="66"],
  [flex-sm="67"] {
    -webkit-flex: 0 0 66.66%;
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
}

@media (min-width: 600px) {
  [hide-gt-sm]:not([show-gt-sm]):not([show-md]):not([show-gt-md]):not([show-lg]):not([show-gt-lg]),
  [hide]:not([show-gt-sm]):not([show-md]):not([show-gt-md]):not([show-lg]):not([show-gt-lg]) {
    display: none !important;
  }

  [layout-gt-sm] {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  [layout-gt-sm=column] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  [layout-gt-sm=row] {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  [flex-gt-sm] {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  [flex-gt-sm="0"] {
    -webkit-flex: 0 0 0%;
    -ms-flex: 0 0 0%;
    flex: 0 0 0%;
    max-width: 0%;
  }

  [flex-gt-sm="5"] {
    -webkit-flex: 0 0 5%;
    -ms-flex: 0 0 5%;
    flex: 0 0 5%;
    max-width: 5%;
  }

  [flex-gt-sm="10"] {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  [flex-gt-sm="15"] {
    -webkit-flex: 0 0 15%;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  [flex-gt-sm="20"] {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  [flex-gt-sm="25"] {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  [flex-gt-sm="30"] {
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  [flex-gt-sm="35"] {
    -webkit-flex: 0 0 35%;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }

  [flex-gt-sm="40"] {
    -webkit-flex: 0 0 40%;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  [flex-gt-sm="45"] {
    -webkit-flex: 0 0 45%;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }

  [flex-gt-sm="50"] {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  [flex-gt-sm="55"] {
    -webkit-flex: 0 0 55%;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
  }

  [flex-gt-sm="60"] {
    -webkit-flex: 0 0 60%;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  [flex-gt-sm="65"] {
    -webkit-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
  }

  [flex-gt-sm="70"] {
    -webkit-flex: 0 0 70%;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }

  [flex-gt-sm="75"] {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  [flex-gt-sm="80"] {
    -webkit-flex: 0 0 80%;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }

  [flex-gt-sm="85"] {
    -webkit-flex: 0 0 85%;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }

  [flex-gt-sm="90"] {
    -webkit-flex: 0 0 90%;
    -ms-flex: 0 0 90%;
    flex: 0 0 90%;
    max-width: 90%;
  }

  [flex-gt-sm="95"] {
    -webkit-flex: 0 0 95%;
    -ms-flex: 0 0 95%;
    flex: 0 0 95%;
    max-width: 95%;
  }

  [flex-gt-sm="100"] {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  [flex-gt-sm="33"],
  [flex-gt-sm="34"] {
    -webkit-flex: 0 0 33.33%;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  [flex-gt-sm="66"],
  [flex-gt-sm="67"] {
    -webkit-flex: 0 0 66.66%;
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  [hide-md]:not([show-md]),
  [hide]:not([show-md]) {
    display: none !important;
  }

  [layout-md] {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  [layout-md=column] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  [layout-md=row] {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  [flex-md] {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  [flex-md="0"] {
    -webkit-flex: 0 0 0%;
    -ms-flex: 0 0 0%;
    flex: 0 0 0%;
    max-width: 0%;
  }

  [flex-md="5"] {
    -webkit-flex: 0 0 5%;
    -ms-flex: 0 0 5%;
    flex: 0 0 5%;
    max-width: 5%;
  }

  [flex-md="10"] {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  [flex-md="15"] {
    -webkit-flex: 0 0 15%;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  [flex-md="20"] {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  [flex-md="25"] {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  [flex-md="30"] {
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  [flex-md="35"] {
    -webkit-flex: 0 0 35%;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }

  [flex-md="40"] {
    -webkit-flex: 0 0 40%;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  [flex-md="45"] {
    -webkit-flex: 0 0 45%;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }

  [flex-md="50"] {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  [flex-md="55"] {
    -webkit-flex: 0 0 55%;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
  }

  [flex-md="60"] {
    -webkit-flex: 0 0 60%;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  [flex-md="65"] {
    -webkit-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
  }

  [flex-md="70"] {
    -webkit-flex: 0 0 70%;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }

  [flex-md="75"] {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  [flex-md="80"] {
    -webkit-flex: 0 0 80%;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }

  [flex-md="85"] {
    -webkit-flex: 0 0 85%;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }

  [flex-md="90"] {
    -webkit-flex: 0 0 90%;
    -ms-flex: 0 0 90%;
    flex: 0 0 90%;
    max-width: 90%;
  }

  [flex-md="95"] {
    -webkit-flex: 0 0 95%;
    -ms-flex: 0 0 95%;
    flex: 0 0 95%;
    max-width: 95%;
  }

  [flex-md="100"] {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  [flex-md="33"],
  [flex-md="34"] {
    -webkit-flex: 0 0 33.33%;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  [flex-md="66"],
  [flex-md="67"] {
    -webkit-flex: 0 0 66.66%;
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
}

@media (min-width: 960px) {
  [hide-gt-md]:not([show-gt-md]):not([show-lg]):not([show-gt-lg]),
  [hide]:not([show-gt-md]):not([show-lg]):not([show-gt-lg]) {
    display: none !important;
  }

  [layout-gt-md] {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  [layout-gt-md=column] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  [layout-gt-md=row] {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  [flex-gt-md] {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  [flex-gt-md="0"] {
    -webkit-flex: 0 0 0%;
    -ms-flex: 0 0 0%;
    flex: 0 0 0%;
    max-width: 0%;
  }

  [flex-gt-md="5"] {
    -webkit-flex: 0 0 5%;
    -ms-flex: 0 0 5%;
    flex: 0 0 5%;
    max-width: 5%;
  }

  [flex-gt-md="10"] {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  [flex-gt-md="15"] {
    -webkit-flex: 0 0 15%;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  [flex-gt-md="20"] {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  [flex-gt-md="25"] {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  [flex-gt-md="30"] {
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  [flex-gt-md="35"] {
    -webkit-flex: 0 0 35%;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }

  [flex-gt-md="40"] {
    -webkit-flex: 0 0 40%;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  [flex-gt-md="45"] {
    -webkit-flex: 0 0 45%;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }

  [flex-gt-md="50"] {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  [flex-gt-md="55"] {
    -webkit-flex: 0 0 55%;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
  }

  [flex-gt-md="60"] {
    -webkit-flex: 0 0 60%;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  [flex-gt-md="65"] {
    -webkit-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
  }

  [flex-gt-md="70"] {
    -webkit-flex: 0 0 70%;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }

  [flex-gt-md="75"] {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  [flex-gt-md="80"] {
    -webkit-flex: 0 0 80%;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }

  [flex-gt-md="85"] {
    -webkit-flex: 0 0 85%;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }

  [flex-gt-md="90"] {
    -webkit-flex: 0 0 90%;
    -ms-flex: 0 0 90%;
    flex: 0 0 90%;
    max-width: 90%;
  }

  [flex-gt-md="95"] {
    -webkit-flex: 0 0 95%;
    -ms-flex: 0 0 95%;
    flex: 0 0 95%;
    max-width: 95%;
  }

  [flex-gt-md="100"] {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  [flex-gt-md="33"],
  [flex-gt-md="34"] {
    -webkit-flex: 0 0 33.33%;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  [flex-gt-md="66"],
  [flex-gt-md="67"] {
    -webkit-flex: 0 0 66.66%;
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
}

@media (min-width: 960px) and (max-width: 1200px) {
  [hide-lg]:not([show-lg]),
  [hide]:not([show-lg]) {
    display: none !important;
  }

  [layout-lg] {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  [layout-lg=column] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  [layout-lg=row] {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  [flex-lg] {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  [flex-lg="0"] {
    -webkit-flex: 0 0 0%;
    -ms-flex: 0 0 0%;
    flex: 0 0 0%;
    max-width: 0%;
  }

  [flex-lg="5"] {
    -webkit-flex: 0 0 5%;
    -ms-flex: 0 0 5%;
    flex: 0 0 5%;
    max-width: 5%;
  }

  [flex-lg="10"] {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  [flex-lg="15"] {
    -webkit-flex: 0 0 15%;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  [flex-lg="20"] {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  [flex-lg="25"] {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  [flex-lg="30"] {
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  [flex-lg="35"] {
    -webkit-flex: 0 0 35%;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }

  [flex-lg="40"] {
    -webkit-flex: 0 0 40%;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  [flex-lg="45"] {
    -webkit-flex: 0 0 45%;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }

  [flex-lg="50"] {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  [flex-lg="55"] {
    -webkit-flex: 0 0 55%;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
  }

  [flex-lg="60"] {
    -webkit-flex: 0 0 60%;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  [flex-lg="65"] {
    -webkit-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
  }

  [flex-lg="70"] {
    -webkit-flex: 0 0 70%;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }

  [flex-lg="75"] {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  [flex-lg="80"] {
    -webkit-flex: 0 0 80%;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }

  [flex-lg="85"] {
    -webkit-flex: 0 0 85%;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }

  [flex-lg="90"] {
    -webkit-flex: 0 0 90%;
    -ms-flex: 0 0 90%;
    flex: 0 0 90%;
    max-width: 90%;
  }

  [flex-lg="95"] {
    -webkit-flex: 0 0 95%;
    -ms-flex: 0 0 95%;
    flex: 0 0 95%;
    max-width: 95%;
  }

  [flex-lg="100"] {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  [flex-lg="33"],
  [flex-lg="34"] {
    -webkit-flex: 0 0 33.33%;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  [flex-lg="66"],
  [flex-lg="67"] {
    -webkit-flex: 0 0 66.66%;
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
}

@media (min-width: 1200px) {
  [hide-gt-lg]:not([show-gt-lg]),
  [hide]:not([show-gt-lg]) {
    display: none !important;
  }

  [layout-gt-lg] {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  [layout-gt-lg=column] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  [layout-gt-lg=row] {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  [flex-gt-lg] {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  [flex-gt-lg="0"] {
    -webkit-flex: 0 0 0%;
    -ms-flex: 0 0 0%;
    flex: 0 0 0%;
    max-width: 0%;
  }

  [flex-gt-lg="5"] {
    -webkit-flex: 0 0 5%;
    -ms-flex: 0 0 5%;
    flex: 0 0 5%;
    max-width: 5%;
  }

  [flex-gt-lg="10"] {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  [flex-gt-lg="15"] {
    -webkit-flex: 0 0 15%;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  [flex-gt-lg="20"] {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  [flex-gt-lg="25"] {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  [flex-gt-lg="30"] {
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  [flex-gt-lg="35"] {
    -webkit-flex: 0 0 35%;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }

  [flex-gt-lg="40"] {
    -webkit-flex: 0 0 40%;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  [flex-gt-lg="45"] {
    -webkit-flex: 0 0 45%;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }

  [flex-gt-lg="50"] {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  [flex-gt-lg="55"] {
    -webkit-flex: 0 0 55%;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
  }

  [flex-gt-lg="60"] {
    -webkit-flex: 0 0 60%;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  [flex-gt-lg="65"] {
    -webkit-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
  }

  [flex-gt-lg="70"] {
    -webkit-flex: 0 0 70%;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }

  [flex-gt-lg="75"] {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  [flex-gt-lg="80"] {
    -webkit-flex: 0 0 80%;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }

  [flex-gt-lg="85"] {
    -webkit-flex: 0 0 85%;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }

  [flex-gt-lg="90"] {
    -webkit-flex: 0 0 90%;
    -ms-flex: 0 0 90%;
    flex: 0 0 90%;
    max-width: 90%;
  }

  [flex-gt-lg="95"] {
    -webkit-flex: 0 0 95%;
    -ms-flex: 0 0 95%;
    flex: 0 0 95%;
    max-width: 95%;
  }

  [flex-gt-lg="100"] {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  [flex-gt-lg="33"],
  [flex-gt-lg="34"] {
    -webkit-flex: 0 0 33.33%;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  [flex-gt-lg="66"],
  [flex-gt-lg="67"] {
    -webkit-flex: 0 0 66.66%;
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
}

[flex-order="0"] {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

[flex-order="1"] {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

[flex-order="2"] {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

[flex-order="3"] {
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}

[flex-order="4"] {
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4;
}

[flex-order="5"] {
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5;
}

[flex-order="6"] {
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6;
}

[flex-order="7"] {
  -webkit-order: 7;
  -ms-flex-order: 7;
  order: 7;
}

[flex-order="8"] {
  -webkit-order: 8;
  -ms-flex-order: 8;
  order: 8;
}

[flex-order="9"] {
  -webkit-order: 9;
  -ms-flex-order: 9;
  order: 9;
}

[layout-align="center"],
[layout-align="center center"],
[layout-align="center start"],
[layout-align="center end"] {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

[layout-align="end"],
[layout-align="end center"],
[layout-align="end start"],
[layout-align="end end"] {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

[layout-align="space-around"],
[layout-align="space-around center"],
[layout-align="space-around start"],
[layout-align="space-around end"] {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

[layout-align="space-between"],
[layout-align="space-between center"],
[layout-align="space-between start"],
[layout-align="space-between end"] {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

[layout-align="center center"],
[layout-align="start center"],
[layout-align="end center"],
[layout-align="space-between center"],
[layout-align="space-around center"] {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

[layout-align="center start"],
[layout-align="start start"],
[layout-align="end start"],
[layout-align="space-between start"],
[layout-align="space-around start"] {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

[layout-align="center end"],
[layout-align="start end"],
[layout-align="end end"],
[layout-align="space-between end"],
[layout-align="space-around end"] {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

[flex-align="start"] {
  align-self: flex-start;
}

[flex-align="center"] {
  align-self: center;
}

[flex-align="end"] {
  align-self: flex-start;
}

[flex-align="stretch"] {
  align-self: stretch;
}

[flex-align="baseline"] {
  align-self: baseline;
}
