@import "../config";

.at-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 36px;
  width: 36px;
  height: 36px;
  border-radius: 3px;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
  position: relative;
  user-select: none;
  font-size: 1rem;
  color: inherit;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: currentColor;
    opacity: 0;
    transform: scale(0);
    transition: all $speed-fast;
    border-radius: 100%;
  }

  &:hover:before, &:focus:before {
    opacity: 0.1;
    transform: scale(0.8);
  }

  &:active:before {
    opacity: 0.2;
    border-radius: 3px;
    transform: scale(1);
  }

  &:disabled, &[disabled] {
    opacity: 0.5;
    pointer-events: none;;
  }

  &.at-icon--small {
    line-height: 24px;
    width: 24px;
    height: 24px;
  }

  &.at-icon--large {
    line-height: 48px;
    width: 48px;
    height: 48px;
  }
}
