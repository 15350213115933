@import "../config";

.at-card {
  @include whiteframe();
  display: block;
  position: relative;
  background-color: #ffffff;
  color: $font-color;
}

.at-card_header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.at-card_content {
  display: block;
  margin: 20px 0;
}

.card-hoverable {
  position : relative;
  cursor   : pointer;

  &:before {
    content        : "";
    background     : #000;
    opacity        : 0;
    position       : absolute;
    transition     : opacity 250ms;
    pointer-events : none;
    left           : 0;
    top            : 0;
    width          : 100%;
    height         : 100%;
  }

  &:hover, &:focus {
    &:before {
      opacity : 0.05;
    }
  }

  &:active {
    &:before {
      opacity : 0.10;
    }
  }
}
